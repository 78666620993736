import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from '../components/tutorial.module.scss';

export type TutorialContentSectionProps = {
  name: string;
  index: string;
  subsections: Array<TutorialContentSubsectionProps>;
};

export type TutorialContentSubsectionProps = {
  name: string;
  index: string;
  id: string;
  content: () => JSX.Element;
};

export type TutorialMenuSectionProps = {
  name: string;
  index: string;
  subsections: Array<TutorialMenuSubsectionProps>;
};

export type TutorialMenuSubsectionProps = {
  name: string;
  index: string;
  id: string;
};

export const TutorialContent = [
  {
    name: 'Lista klientów',
    subsections: [
      {
        name: 'Jak działa moduł filtrowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł filtrowania zawiera szereg filtrów służących do zawężania wyników na liście klientów. Można
                dowolnie ustawiać poszczególne filtry, aby dopasować listę klientów do bieżących potrzeb. Filtry
                działają jednocześnie, co oznacza, że jeśli zostaną ustawione np. trzy filtry naraz, to na liście
                wyników pojawią się klienci spełniający jednocześnie te trzy kryteria. Jeśli nie zostanie użyty żaden
                filtr, to na liście klientów znajdą się wszyscy klienci, którzy zostali dodani do RiskRadar.
              </p>
              <p>W module dostępne są następujące filtry: </p>
              <ul>
                <li>
                  Monitorowanie klienta – pozwala wylistować klientów, którzy mają włączone / wyłączone monitorowanie w
                  RiskRadar, przy czym:
                  <ul>
                    <li>
                      jeśli zaznaczona zostanie opcja „włączone” to lista wyników uwzględni klientów, którzy mają
                      monitorowanie włączone i poprawnie uruchomione,
                    </li>
                    <li>
                      jeśli zaznaczona zostanie opcja „wyłączone” to lista wyników uwzględni klientów, którzy: mają
                      monitorowanie wyłączone, mają monitorowanie w trakcie uruchamiania, mają monitorowanie w trakcie
                      wyłączania,
                    </li>
                    <li>
                      jeśli obie opcje (tj. „włączone” oraz „wyłączone”) są jednocześnie zaznaczone lub odznaczone to
                      lista wyników uwzględni wszystkich klientów danej firmy (o ile nie są ustawione inne filtry).
                    </li>
                  </ul>
                </li>
                <li>
                  Sprzedaż brutto - pozwala wylistować klientów, do których wartość sprzedaży brutto jest we wskazanym
                  przedziale kwotowym.
                </li>
                <li>
                  Należności brutto - pozwala wylistować klientów, od których wartość należności brutto jest we
                  wskazanym przedziale kwotowym.
                </li>
                <li>
                  Wiarygodność płatnicza – pozwala wylistować klientów, którzy posiadają daną kategorię wiarygodności
                  płatniczej według informacji z KRD BIG S.A.
                </li>
                <li>
                  Zadłużenie w KRD – pozwala wylistować klientów, których zadłużenie według danych z KRD BIG S.A. jest
                  we wskazanym przedziale kwotowym.
                </li>
                <li>
                  Limit kupiecki – pozwala wylistować klientów, których kwota limitu kupieckiego jest we wskazanym
                  przedziale kwotowym lub limit kupiecki nie został wyznaczony.
                </li>
                <li>
                  Ostatni dług dodany do KRD – pozwala wylistować klientów, dla których zidentyfikowane zostało
                  zdarzenie dotyczące dopisania negatywnej informacji gospodarczej do KRD BIG S.A. w dniu wczorajszym
                  lub w ostatnich 7 / 30 / 60 / 90 dniach lub jest brak zdarzeń.
                </li>
                <li>
                  Zdarzenia w KRD – pozwala wylistować klientów, dla których pojawiły się jakiekolwiek zdarzenia w
                  ramach sprawdzania zdarzeń w KRD BIG S.A. w dniu wczorajszym lub w ostatnich 7 / 30 / 60 / 90 dniach
                  lub jest brak zdarzeń.
                </li>
                <li>
                  Zmiana wiarygodności – pozwala wylistować klientów, dla których nastąpiła zmiana kategorii
                  wiarygodności płatniczej w dniu dzisiejszym lub w ostatnich 7 / 30 / 60 / 90 dniach lub zmiana nie
                  wystąpiła.
                </li>
                <li>
                  Zmiana limitu kupieckiego - pozwala wylistować klientów, dla których nastąpiła zmiana limitu
                  kupieckiego w dniu dzisiejszym lub w ostatnich 7 / 30 / 60 / 90 dniach lub zmiana nie wystąpiła.
                </li>
                <li>
                  Pobrany raport o kliencie – pozwala wylistować klientów, dla których został pobrany przynajmniej jeden
                  Raport KG (w ramach usługi „Raport KG”) i nie jest on starszy niż 7 dni.
                </li>
              </ul>
              <p>
                Ustawienia filtrowania i wyszukiwania są zapamiętywane do zakończenia sesji. Oznacza to, że jeśli
                przykładowo ustawionych zostanie kilka filtrów a potem nastąpi przejście do innej zakładki (np. do
                Samouczka), to przy powrocie do zakładki „Lista klientów” ustawienia filtrów i wyszukiwania będą takie
                jak były wcześniej, nie zostaną wyczyszczone. W celu wyczyszczenia filtrów, należy skorzystać z
                przycisku „Wyczyść filtry”.
              </p>
              <p>
                W przypadku, gdy wartości w filtrach nie były zmieniane lub przywrócono im wartości domyślne, wówczas
                przycisk „Wyczyść filtry” jest szary i nieaktywny. Jeśli natomiast wartości w filtrach były zmieniane
                przez użytkownika i są inne niż domyślne, wówczas przycisk będzie aktywny i koloru zielonego.
              </p>
              <p className={styles.textUnderline}>Przykład zastosowania filtrów</p>
              <p>
                W celu sprawdzenia, którzy monitorowani klienci zostali usunięci z systemu KRD BIG S.A. w ostatnim
                tygodniu, należy ustawić filtry w następujący sposób:
              </p>
              <ul>
                <li>Zadłużenie w KRD: od 0 do 0,</li>
                <li>Zdarzenia w KRD: do 7 dni.</li>
              </ul>
              <p>Wizualizacja poglądowa modułu z filtrami:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-1a.png')}
                  alt="Jak działa moduł filtrowania?"
                  className={styles.verticalImg}
                />
                <img
                  src={require('../../../assets/images/tutorial/1-1b.png')}
                  alt="Jak działa moduł filtrowania?"
                  className={`${styles.verticalImg} ${styles.verticalImgFullHeight}`}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa moduł z listą klientów?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł z listą klientów zawiera najważniejsze informacje o klientach. Pozycje na liście zawierają
                informacje o poszczególnych klientach. Można elastycznie dopasowywać listę klientów do bieżących potrzeb
                za pomocą szeregu filtrów.
              </p>
              <p>
                Może zdarzyć się sytuacja, że dany klient wystąpi więcej niż jeden raz na liście. Może to wynikać z
                tego, że klient ten był dodany do RiskRadar zarówno ręcznie, jak i za pomocą importu CSV. Dotyczy to
                również sytuacji, gdy ręcznie zostaną dodani klienci o tym samym numerze NIP (np. oddziały danej firmy).
                W takich sytuacjach informacje o usługach (tj. zadłużenie w KRD BIG S.A., kategoria wiarygodności
                płatniczej, raport) będą identyczne dla każdego wiersza z tym samym numerem NIP. Opłaty za usługi są
                wówczas naliczane jak za jeden podmiot.
              </p>
              <p>
                Z poziomu listy klientów można otworzyć kartę danego klienta i zapoznać się z bardziej szczegółowymi
                informacjami na jego temat.
              </p>
              <p>
                W module z listą klientów można włączać usługę monitorowania klienta. Można to zrobić na dwa sposoby:
              </p>
              <ul>
                <li>pojedynczo dla wybranego klienta,</li>
                <li>grupowo dla wielu wybranych klientów jednocześnie.</li>
              </ul>
              <p>
                Moduł ten daje też możliwość pobierania aktualnego Raportu KG na temat danego klienta oraz otwarcia
                ostatniego Raportu KG pobranego w ciągu ostatnich 7 dni.
              </p>
              <p>Lista klientów składa się z następujących kolumn:</p>
              <ul>
                <li>Checkboxy – służą do wykonywania akcji grupowych na wielu klientach,</li>
                <li>Klient – zawiera nazwę klienta (informacja dodana ręcznie lub w procesie importu pliku CSV),</li>
                <li>NIP – zawiera NIP klienta (informacja dodana ręcznie lub w procesie importu pliku CSV),</li>
                <li>
                  Wiarygodność – zawiera informację o kategorii wiarygodności płatniczej klienta (informacja dostarczona
                  przez KRD BIG S.A.),
                </li>
                <li>
                  Zadłużenie w KRD (PLN) – zawiera wartość łącznego zadłużenia danego klienta na podstawie informacji
                  negatywnych wpisanych do KRD BIG S.A. (dane dostarczone przez KRD BIG S.A.),
                </li>
                <li>
                  Limit kupiecki (PLN) – zawiera informację o limicie kupieckim klienta (informacja dostarczona przez
                  KRD BIG S.A.),
                </li>
                <li>Raporty – zawiera linki do Raportów KG (dostarczanych przez KG sp.j.),</li>
                <li>Opis 1 – zawiera dowolny opis klienta wprowadzony przez użytkownika,</li>
                <li>Opis 2 – zawiera dowolny opis klienta wprowadzony przez użytkownika,</li>
                <li>
                  Sprzedaż (PLN) – zawiera wartość sprzedaży brutto (informacja dodana w procesie importu pliku CSV),
                </li>
                <li>
                  Należności (PLN) – zawiera wartość należności brutto (informacja dodana w procesie importu pliku CSV).
                </li>
              </ul>
              <p>
                W module tym można ręcznie dodawać klientów za pomocą przycisku „Dodaj klienta”. Po jego kliknięciu w
                nowej zakładce przeglądarki otwiera się ekran, za pomocą którego można dodać podmiot do RiskRadar.
              </p>
              <p>
                Wyniki na liście klientów są stronicowane. Domyślnie na jednej stronie prezentowanych jest 100 klientów.
                Można zmienić to ustawienie według własnych preferencji (np. 10, 20, 50 wyników na jednej stronie).
              </p>
              <p>Wizualizacja poglądowa listy klientów:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-2.png')}
                  alt="Jak działa moduł z listą klientów?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa moduł z wykresem?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Moduł ten przedstawia portfel klientów dodanych do RiskRadar według kategorii wiarygodności płatniczej.
              </p>
              <p>
                Wykres słupkowy obrazuje liczbę klientów, wartość sprzedaży lub wartość należności dotyczącą klientów w
                poszczególnych kategoriach wiarygodności płatniczej – od A do H.
              </p>
              <p>
                Domyślnie wykres pokazuje liczbę klientów, jednak można to zmienić na wartość sprzedaży lub wartość
                należności. W celu zmiany wyświetlanych wartości, należy korzystać z przycisków „Liczba klientów”,
                „Sprzedaż” lub „Należności” w prawej górnej części modułu.
              </p>
              <p>
                W ramach pozycji „Brak kategorii” uwzględniani są klienci, dla których monitorowanie jest włączone,
                jednak w danym momencie nie jest możliwe wykonanie analizy wiarygodności płatniczej. Przyczyny braku
                kategorii są opisane w Samouczku w punkcie „4.1. Czym jest kategoria wiarygodności płatniczej w ramach
                analizy wiarygodności płatniczej?”.
              </p>
              <p>
                Wykres zawiera też pozycję „Brak włączonego monitorowania” – pokazuje ona dane dotyczące klientów, dla
                których aktualnie nie jest uruchomione monitorowanie, a tym samym nie ma informacji o kategorii
                wiarygodności płatniczej, zdarzeniach, poziomie zadłużenia.
              </p>
              <p>
                Kliknięcie na przyciski z liczbami klientów znajdujące się bezpośrednio pod wykresem spowoduje
                automatyczne ustawienie kryteriów filtrowania oraz wylistowanie klientów spełniających te kryteria.
                Przykładowo, jeśli nastąpi kliknięcie na przycisk „19 klientów” znajdujący się pod słupkiem z kategorią
                D, to w filtrze „Wiarygodność płatnicza” zostanie zaznaczona pozycja D, a na liście klientów pojawi się
                19 klientów z kategorią wiarygodności płatniczej D.
              </p>
              <p>
                Wykres słupkowy obejmuje wszystkich klientów danej firmy dodanych do RiskRadar, także tych, którzy nie
                mają podanej wartości sprzedaży czy należności (RiskRadar przyjmuje wtedy, że wartość sprzedaży czy
                należności równa jest zeru). W przypadku, gdy w RiskRadar istnieje więcej niż jeden klient o tym samym
                numerze NIP, na potrzeby wykresu słupkowego liczony jest on pojedynczo (aby uniknąć wielokrotnego
                liczenia np. sprzedaży).
              </p>
              <p>
                Sekcja „Klienci wpisani do KRD” stanowi podsumowanie liczby klientów wpisanych do KRD BIG S.A. oraz
                aktualnego zadłużenia tych klientów. Liczba klientów w tej sekcji nie musi pokrywać się z liczbą
                klientów posiadających kategorię wiarygodności płatniczej H, ponieważ może się zdarzyć, że nawet klient
                posiadający kategorię A jest wpisany do KRD BIG S.A. na niewielką kwotę. Więcej informacji na temat
                kategorii wiarygodności płatniczej znajduje się w Samouczku w punkcie „4.1. Czym jest kategoria
                wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?”.
              </p>
              <p>
                Wykres kołowy pokazuje liczbę klientów w poszczególnych kategoriach wiarygodności płatniczej. Liczby
                klientów są tu analogiczne jak na wykresie słupkowym. Wewnątrz wykresu kołowego są widoczne 2 liczby:
              </p>
              <ul>
                <li>„X klientów w RiskRadar” – obrazuje, ilu klientów jest aktualnie w RiskRadar,</li>
                <li>„w tym monitorowanych Y” – obrazuje, ilu klientów ma aktualnie włączone monitorowanie.</li>
              </ul>
              <p>Wizualizacja poglądowa modułu z wykresem:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-3.png')}
                  alt="Jak działa moduł z wykresem?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działają akcje grupowe?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Akcje grupowe pozwalają na wykonywanie operacji na wielu klientach jednocześnie. W celu wykonania akcji
                grupowej trzeba najpierw zaznaczyć klientów. Można tego dokonać na dwa sposoby.
              </p>
              <ul>
                <li>
                  Po pierwsze, można zaznaczyć checkboxy przy poszczególnych klientach – ta metoda jest lepsza, gdy jest
                  potrzeba zaznaczenia wybranych klientów z listy.
                </li>
                <li>
                  Po drugie, można zaznaczyć od razu wszystkich klientów na danej stronie za pomocą checkboxu zawartego
                  w nagłówku tabeli. Użycie tego checkboxa powoduje zaznaczenie wszystkich klientów widocznych na danej
                  stronie, nie powoduje natomiast zaznaczenia wszystkich klientów na liście wyników.
                  <ul>
                    <li>
                      Przykładowo, jeśli lista wyników zawiera 100 klientów, a na stronie widać 20 z nich, to użycie
                      checkboxu zawartego w nagłówku tabeli spowoduje zaznaczenie 20, a nie 100 klientów. Metoda ta jest
                      lepsza, gdy jest potrzeba szybkiego zaznaczenia wszystkich klientów widocznych na danej stronie.
                      Jeśli trzeba oznaczyć za jednym razem większą liczbę klientów, to można zwiększyć liczbę wyników
                      pokazywanej na jednej stronie z 20 do np. 100 za pomocą pola wyboru umieszczonego w prawej dolnej
                      części modułu.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>Po zaznaczeniu klientów można wykonać następujące akcje grupowe dotyczące wybranych klientów:</p>
              <ul>
                <li>
                  włączenie monitorowania – ta akcja uruchomi proces włączenia monitorowania na wszystkich wybranych
                  klientach, za wyjątkiem tych, dla których monitorowanie jest już włączone (więcej informacji na temat
                  włączania monitorowania znajduje się w Samouczku w punkcie „3.2. Jak włączyć usługę monitorowania?”),
                </li>
                <li>
                  wyłączenie monitorowania – ta akcja uruchomi proces wyłączenia monitorowania na wszystkich wybranych
                  klientach, u których jest włączone monitorowanie; przy czym nie jest możliwe wykonanie tej operacji,
                  jeżeli wśród zaznaczonych klientów jest co najmniej jeden, dla którego monitorowanie nie jest
                  włączone.
                </li>
              </ul>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-4.png')}
                  alt="Jak działają akcje grupowe?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>
                Wykonywanie akcji grupowych jest wygodne, ale trzeba pamiętać, że ma wpływ na licznik wykorzystania
                usług. Jeśli przykładowo monitorowanie zostanie włączone dla 100 klientów choćby na krótki czas (np.
                kilka sekund), to spowoduje to zwiększenie stanu licznika usługi monitorowania o 100. Jeśli limit liczby
                monitorowanych klientów w ramach opłaty miesięcznej został już wcześniej wykorzystany, to za włączenie
                monitorowania na tych 100 klientach zostanie naliczona dodatkowa opłata jednostkowa zgodnie z cennikiem.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak działa „Eksport do csv”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Funkcjonalność „Eksportu do csv” pozwala wyeksportować dane z aktualnego widoku do pliku csv. Dzięki
                temu można pracować na tych danych poza serwisem RiskRadar, w tym przetwarzać je stosownie do potrzeb.
                Eksportowane są dane o klientach uwzględnionych na liście wyników w danej chwili, z uwzględnieniem
                filtrów, wyszukiwania, sortowania i wszystkich stron wyników.
              </p>
              <p>
                Gotowy plik csv z wyeksportowanymi danymi będzie dostępny w zakładce menu „Historia eksportu danych”. Po
                zakończeniu eksportu danych w serwisie RiskRadar w prawym górnym rogu ekranu pojawi się zielone
                powiadomienie z linkiem prowadzącym do zakładki „Historia eksportu danych”. Gdy plik będzie gotowy do
                pobrania, wysłane zostanie także powiadomienie e-mail. Plik jest dostępny do pobrania przez 7 dni, po
                tym czasie zostanie trwale usunięty z RiskRadar.
              </p>
              <p>Wizualizacja poglądowa zakładki „Eksport danych”:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/1-5.png')}
                  alt='Jak działa funkcja "Eksport do csv”?'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Karta klienta',
    subsections: [
      {
        name: 'Sekcja "Dane klienta"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Sekcja ta zawiera podstawowe informacje na temat danego klienta (NIP, nazwa, opis, adres). Pole „Źródło”
                zawiera informację, w jaki sposób klient został dodany do RiskRadar – ręcznie lub za pomocą importu CSV.
                Można edytować dane klienta, który został ręcznie dodany do RiskRadar. Nie można jednak edytować danych
                klienta, który został dodany do RiskRadar za pomocą importu CSV. Więcej informacji na temat importu CSV
                znajduje się w Samouczku w punkcie „7.3. Do czego służy i jak działa import za pomocą pliku CSV?”.
              </p>
              <p>
                W celu wprowadzenia zmian dla klienta dodanego ręcznie do RiskRadar, należy użyć przycisku „Modyfikuj”,
                który otwiera ekran edycji danych klienta w nowej zakładce przeglądarki. Nie ma możliwości edytowania
                pola z numerem NIP. Po wprowadzeniu zmian należy je zapisać za pomocą przycisku „Zapisz”. Więcej
                informacji na temat dodawania nowego klienta do RiskRadar oraz usuwaniu klienta istniejącego znajduje
                się w Samouczku w punkcie „7. Zarządzanie klientami”.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-1.png')}
                  alt='Sekcja "Dane klienta"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Wiarygodność płatnicza"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowana jest informacja o kategorii wiarygodności płatniczej klienta stanowiąca wynik
                analizy wiarygodności płatniczej. Informacja ta pochodzi z KRD BIG S.A. i jest udostępniana poprzez
                RiskRadar. Informacja o kategorii wiarygodności płatniczej jest generowana na bazie własnych algorytmów
                KRD BIG S.A., niezależnie od informacji o klientach zawartych w RiskRadar. Informacja ta jest
                prezentowana tylko dla tych klientów, dla których w RiskRadar jest włączona usługa monitorowania. W celu
                uzyskania informacji o kategorii wiarygodności płatniczej danego klienta, najpierw należy włączyć jego
                monitorowanie w RiskRadar. Analiza wiarygodności płatniczej jest jednym z elementów usługi monitorowania
                w RiskRadar. Więcej informacji na temat analizy wiarygodności płatniczej znajduje się w Samouczku w
                punkcie „4.1. Czym jest kategoria wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?”.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-3.png')}
                  alt='Sekcja "Wiarygodność płatnicza"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Monitorowanie"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Sekcja zawiera bieżące informacje dotyczące danego klienta pochodzące z KRD BIG S.A. tj.:</p>
              <ul>
                <li>
                  informacja o łącznej kwocie zadłużenia na podstawie negatywnych informacji gospodarczych z KRD BIG
                  S.A. lub o braku informacji gospodarczych oraz
                </li>
                <li>
                  lista zdarzeń z ostatnich 90 dni będąca wynikiem sprawdzania zdarzeń dotyczących klienta o danym
                  numerze NIP w KRD BIG S.A.
                </li>
              </ul>
              <p>
                Sekcja zawiera również suwak służący do włączenia/wyłączenia usługi monitorowania w RiskRadar oraz
                komunikat o dacie wyłączenia monitorowania, jeśli użytkownik ustawił ją jako datę przyszłą (np. za
                tydzień od dziś). W celu uzyskania tego rodzaju informacje o kliencie, najpierw należy włączyć jego
                monitorowanie. Więcej informacji na temat usługi monitorowania znajduje się w Samouczku w punkcie „3.
                Usługa monitorowania klienta”.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-4.png')}
                  alt='Sekcja "Monitorowanie"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Raporty"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowane są Raporty KG o kliencie pobrane z poziomu serwisu RiskRadar oraz z poziomu
                raportów cyklicznych. Aktualny Raport KG można też pobrać za pomocą przycisku „Pobierz nowy raport”.
                Jeśli w ramach danej firmy Raporty KG o danym kliencie pobrało kilku użytkowników, to w tej sekcji
                zostaną wyświetlone wszystkie takie raporty. Raporty KG starsze niż 7 dni nie są prezentowane z uwagi na
                ryzyko ich nieaktualności. Chcąc zachować dany Raport KG na dłużej niż 7 dni, można go w każdej chwili
                pobrać na swoje urządzenie jako plik PDF. Więcej informacji na temat Raportów KG znajduje się w
                Samouczku w punkcie „5. Usługa raportu o kliencie”.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-5.png')}
                  alt='Sekcja "Raporty"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja "Sprzedaż i należności"',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Sekcja ta zawiera informacje o wartości sprzedaży brutto za ostatnie 12 miesięcy oraz wartości
                należności brutto na dany dzień. Dane te są wprowadzane do RiskRadar w drodze importu pliku CSV (są to
                dane o charakterze opcjonalnym, nie ma konieczności ich wprowadzania). Nie ma możliwości wprowadzenia
                tych informacji w trybie ręcznego dodawania klienta. Dokonując porównań pomiędzy tymi wartościami warto
                pamiętać, że wartość sprzedaży dotyczy pewnego okresu (12 miesięcy), tymczasem wartość należności to
                stan na dany dzień (a dokładniej na dzień importu), przez co może także uwzględniać należności spoza
                okresu, którego dotyczy wartość sprzedaży. Daty podane w nawiasach oznaczają datę importu informacji do
                RiskRadar.
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-2.png')}
                  alt='Sekcja "Sprzedaż i należności"'
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Sekcja „Limit kupiecki”',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W tej sekcji prezentowana jest informacja o limicie kupieckim klienta stanowiąca wynik analizy
                wiarygodności płatniczej. Informacja ta pochodzi z KRD BIG S.A. i jest udostępniana poprzez RiskRadar.
                Informacja o limicie kupieckim jest generowana na bazie własnych algorytmów KRD BIG S.A., niezależnie od
                informacji o klientach zawartych w RiskRadar. Informacja ta jest prezentowana tylko dla tych klientów,
                dla których w RiskRadar jest włączona usługa monitorowania. Chcąc uzyskać informację o limicie kupieckim
                danego klienta, najpierw należy włączyć jego monitorowanie w RiskRadar. Analiza wiarygodności płatniczej
                jest jednym z elementów usługi monitorowania w RiskRadar. Więcej informacji na temat analizy
                wiarygodności płatniczej znajduje się w Samouczku w punkcie „4.2. Czym jest limit kupiecki w ramach
                analizy wiarygodności płatniczej?”.
              </p>
              <p>W sekcji tej prezentowane są następujące informacje dotyczące limitu kupieckiego:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>Wizualizacja poglądowa dla sytuacji, gdy monitorowanie jest włączone:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/2-6.png')}
                  alt="Sekcja „Limit kupiecki”"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Usługa monitorowania klienta',
    subsections: [
      {
        name: 'Na czym polega usługa monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Monitorowanie klienta w RiskRadar polega na codziennym sprawdzaniu jego sytuacji w KRD BIG S.A. i
                wyświetlaniu następujących informacji:
              </p>
              <ul>
                <li>
                  zdarzeń odnotowanych w KRD BIG S.A. o danym podmiocie (w ramach usługi „Monitorowanie PRO” KRD BIG
                  S.A.),
                </li>
                <li>
                  wartości łącznego zadłużenia danego podmiotu (w ramach usługi „Udostępnianie informacji gospodarczej”
                  KRD BIG S.A.),
                </li>
                <li>
                  kategorii wiarygodności płatniczej danego podmiotu (w ramach usługi „Analiza wiarygodności płatniczej
                  podmiotów niebędących konsumentem” KRD BIG S.A.).
                </li>
                <li>
                  limitu kupieckiego danego podmiotu (w ramach usługi „Analiza wiarygodności płatniczej podmiotów
                  niebędących konsumentem” KRD BIG S.A.).
                </li>
              </ul>
              <p>
                Uruchomienie sprawdzania zdarzeń wyzwala uruchomienie pobierania informacji o wartości łącznego
                zadłużenia (informacja gospodarcza) oraz uruchomienie pobierania informacji o kategorii wiarygodności
                płatniczej i limicie kupieckim.
              </p>
              <p>
                KRD BIG S.A. jako Biuro Informacji Gospodarczej może zgodnie z „Ustawą o udostępnianiu informacji
                gospodarczych i wymianie danych gospodarczych” przyjmować informacje gospodarcze od wierzycieli,
                przechowywać je i ujawniać na zasadach określonych w tej ustawie. KRD BIG S.A. udostępnia tylko
                informacje aktualne, nie udostępnia informacji historycznych.
              </p>
              <p>
                KRD BIG S.A. udostępnia mechanizmy monitorowania zdarzeń związanych z udostępnianymi przez KRD BIG S.A.
                informacjami gospodarczymi w zakresie dotyczącym informacji o dłużniku na następujących zasadach:
              </p>
              <ul>
                <li>
                  mechanizm monitorowania polega na informowaniu o zmianach dotyczących monitorowanego podmiotu w
                  systemie KRD BIG S.A., w szczególności o fakcie dopisania, usunięcia lub aktualizacji informacji
                  gospodarczej, bez specyfikowania treści informacji gospodarczych, albo o braku zmian,
                </li>
                <li>
                  informacja dostarczana jest przez KRD BIG S.A. poprzez kanał komunikacyjny, którym jest RiskRadar,
                </li>
                <li>
                  informacja o zdarzeniu udzielona w ramach monitorowania nie ma charakteru informacji gospodarczej.
                </li>
              </ul>
              <h4>Sprawdzanie zdarzeń</h4>
              <p>W ramach sprawdzania zdarzeń w KRD BIG S.A. uwzględniane są: </p>
              <ul>
                <li>dodanie sumy zobowiązań od jednego wierzyciela na kwotę większą niż 0 zł.,</li>
                <li>usunięcie sumy zobowiązań od jednego wierzyciela na kwotę większą niż 0 zł.,</li>
                <li>aktualizacja sumy zobowiązań w górę o kwotę większą niż 0 zł.,</li>
                <li>aktualizacja sumy zobowiązań w dół o kwotę większą niż 0 zł.,</li>
                <li>łączna ilość wierzycieli dłużnika większa niż 0,</li>
                <li>łączna kwota zadłużenia w bazie dla dłużnika jest większa niż 0 zł.,</li>
                <li>dodanie dłużnika do bazy KRD BIG S.A.,</li>
                <li>usunięcie dłużnika z bazy KRD BIG S.A.,</li>
                <li>zmiana danych dłużnika (np. nazwy, adresu).</li>
              </ul>
              <h4>Pobieranie informacji o stanie zadłużenia</h4>
              <p>
                Pobieranie informacji o wartości łącznego zadłużenia polega na odpytywaniu bazy KRD BIG S.A. o
                informacje gospodarcze w postaci salda zadłużenia danego podmiotu.
              </p>
              <p>
                Pobranie informacji gospodarczej o wysokości łącznego zadłużenia danego podmiotu jest wykonywane po raz
                pierwszy w momencie udostępnienia usługi „Monitorowanie PRO” oraz później za każdym razem, gdy (w ramach
                uruchomionej już usługi „Monitorowanie PRO”) wystąpiło jedno z poniższych zdarzeń odnośnie danego
                podmiotu:
              </p>
              <ul>
                <li>aktualizacja sumy zobowiązań w górę,</li>
                <li>aktualizacja sumy zobowiązań w dół,</li>
                <li>dodanie sumy zobowiązań od jednego Wierzyciela,</li>
                <li>usunięcie sumy zobowiązań od jednego Wierzyciela,</li>
                <li>łączna ilość wierzycieli dłużnika,</li>
                <li>łączna kwota zadłużenia w bazie dla dłużnika,</li>
                <li>dodanie dłużnika do bazy KRD,</li>
                <li>usunięcie dłużnika z bazy KRD.</li>
              </ul>
              <p>Dla danego podmiotu w ciągu jednej doby może wystąpić tylko jedno z tych zdarzeń albo żadne z nich.</p>
              <p>
                W przypadku braku powyższych zdarzeń w ramach usługi „Monitorowanie PRO” powodujących aktualizację
                wysokości łącznego zadłużenia, pobranie informacji gospodarczej o wysokości łącznego zadłużenia danego
                podmiotu jest wykonywane zawsze po 90 dniach od ostatniego pobrania informacji gospodarczej o wysokości
                łącznego zadłużenia danego podmiotu.
              </p>
              <h4>Pobieranie informacji o kategorii wiarygodności płatniczej</h4>
              <p>
                Kategoria wiarygodności płatniczej danego podmiotu jest nadawana przez KRD BIG S.A. w postaci liter od A
                do H w wyniku analizy wiarygodności płatniczej tego podmiotu.
              </p>
              <p>
                Pobranie informacji o kategorii wiarygodności płatniczej danego podmiotu jest wykonywane po raz pierwszy
                w momencie udostępnienia usługi „Monitorowanie PRO” a następnie raz na dobę w godzinach rannych.
              </p>
              <p>
                W przypadku wystąpienia zdarzeń wpływających na kategorię wiarygodności płatniczej (np. znaczny wzrost
                zadłużenia w systemie KRD BIG S.A.) informacja o zmianie kategorii wiarygodności płatniczej w serwisie
                RiskRadar może być widoczna z opóźnieniem jednego dnia, ponieważ pobieranie informacji o kategorii
                wiarygodności płatniczej danego podmiotu odbywa się raz na dobę.
              </p>
              <p>
                Więcej informacji na ten temat znajduje się w Samouczku w punkcie „4.1. Czym jest kategoria
                wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?”.
              </p>
              <p className={styles.textUnderline}>Pobieranie informacji o limicie kupieckim</p>
              <p>
                Limit kupiecki danego podmiotu jest wyznaczany przez KRD BIG S.A. w postaci określonej kwoty w wyniku
                analizy wiarygodności płatniczej tego podmiotu.
              </p>
              <p>
                Limit kupiecki to maksymalna kwota, wyrażona w PLN, do jakiej (na podstawie analizy statystycznej)
                rekomendowana jest sprzedaż kontrahentowi usług lub towarów z odroczonym terminem płatności.
              </p>
              <p>W ramach usługi limitu kupieckiego dostępne są następujące informacje:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>
                Więcej informacji na ten temat znajduje się w Samouczku w punkcie „4.2. Czym jest limit kupiecki w
                ramach analizy wiarygodności płatniczej?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak włączyć usługę monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Monitorowanie klienta można włączyć na 3 sposoby:</p>
              <ul>
                <li>
                  w karcie klienta w sekcji „Monitorowanie” – za pomocą suwaka,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2a.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – pojedynczo, za pomocą przycisku „Włącz monitorowanie”,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2b.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – zbiorczo, za pomocą akcji grupowej „Włącz monitorowanie”, po uprzednim
                  zaznaczeniu klientów,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2c.png')}
                      alt="Jak włączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  za pomocą odpowiedniego kodu operacji w ramach importu pliku CSV (więcej informacji na ten temat
                  znajduje się w Samouczku w punkcie „7.6. Wykonywanie działań masowych za pomocą kodów operacji przy
                  imporcie pliku CSV.”).
                </li>
              </ul>
              <p>Uruchomienie monitorowania klienta w RiskRadar powoduje jednoczesne uruchomienie:</p>
              <ul>
                <li>pobierania informacji o łącznym zadłużeniu w KRD BIG S.A.,</li>
                <li>pobierania informacji o kategorii wiarygodności płatniczej wyznaczanym przez KRD BIG S.A.,</li>
                <li>pobierania informacji o limicie kupieckim wyznaczanym przez KRD BIG S.A.,</li>
                <li>pobierania informacji o zdarzeniach w KRD BIG S.A.</li>
              </ul>
              <p>Od momentu zlecenia włączenia monitorowania:</p>
              <ul>
                <li>informacja z KRD BIG S.A. o łącznym zadłużeniu – pojawi się do 2 godzin,</li>
                <li>informacja z KRD BIG S.A. o kategorii wiarygodności płatniczej – pojawi się do 2 godzin, </li>
                <li>informacja z KRD BIG S.A. o limicie kupieckim – pojawi się do 2 godzin,</li>
                <li>informacja z KRD BIG S.A. o zdarzeniach – pojawi się po 2 dniach. </li>
              </ul>
              <p>
                Pełne uruchomienie monitorowania nie następuje więc natychmiast, a po jednej pełnej dobie następującej
                po jego włączeniu (doba rozumiana jest jako okres od godziny 00:00 do godziny 23:59, a nie jako okres
                24h liczony od momentu włączenia do momentu uruchomienia monitorowania). Przykładowo, jeśli
                monitorowanie zostanie włączone w poniedziałek o godzinie 15, to informacje o zadłużeniu, kategorii
                wiarygodności oraz limicie kupieckim pojawią się w poniedziałek do godziny 17 natomiast informacje o
                zdarzeniach (o ile takowe wystąpią) pojawią się w środę około godziny 1 w nocy. Mechanizm ten dotyczy
                zarówno włączania monitorowania dla pojedynczego klienta, jak i włączania monitorowania dla wielu
                klientów jednocześnie, w ramach akcji grupowej.
              </p>
              <p>
                Jeśli próba włączenia monitorowania ma miejsce po godzinie 23:00 to pojawi się komunikat o braku
                możliwości wykonania operacji w tym dniu oraz propozycja włączenia monitorowania w kolejnym dniu. Po
                wyrażeniu zgody, monitorowanie zostanie włączone w kolejnym dniu.
              </p>
              <p>
                Naliczenie usług następuje w momencie włączenia monitorowania (tj. kliknięcia przycisku „Włącz
                monitorowanie”) a nie z chwilą uruchomienia monitorowania (tj. rozpoczęcia wyświetlania informacji w
                RiskRadar). Naliczenie odbywa się w ramach przyznanego limitu (jeśli limit nie został wykorzystany) lub
                w ramach przekroczenia (jeśli limit został już wykorzystany). Więcej informacji na ten temat znajduje
                się w Samouczku w punkcie „6. Opłaty miesięczne i przekroczenia”.
              </p>
              <p>
                Z uwagi na to, że włączanie monitorowania powoduje naliczanie usług, należy wykonywać to działanie
                rozważnie, po przemyśleniu, czy rzeczywiście ma zostać rozpoczęte monitorowanie danego klienta lub
                klientów. Przykładowo, jeśli włączone zostanie monitorowanie jednocześnie dla 50 klientów w ramach akcji
                grupowej, celowo lub przypadkowo, to spowoduje to naliczenie usług.
              </p>
              <p>
                Dodatkowe informacje dotyczące przekraczania limitów dostępnych w ramach opłaty miesięcznej – wraz z
                konkretnymi przykładami – opisane są w Samouczku w punkcie „6.2. Jakie są opłaty za przekroczenie
                limitów usług w ramach opłaty miesięcznej?”.
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania danego klienta filtr „Monitorowanie klienta”
                w module filtrowania traktuje tego klienta tak jakby miał status monitorowania wyłączony (informacje z
                monitorowania nie są bowiem jeszcze dostępne w RiskRadar).
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania można wykonać operację jego wyłączenia,
                jednak takie działanie nie cofa licznika usług. Innymi słowy, jeśli włączone zostanie monitorowanie np.
                o 13:20, a następnie wyłączone o 13:25 to usługa zostanie naliczona (w ramach opłaty miesięcznej lub
                jako przekroczenie limitu).
              </p>
              <p>
                Informacje na temat licznika usług znajdują się w Samouczku w punkcie „6.4. Jak działa licznik usług?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć usługę monitorowania?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Monitorowanie klienta można wyłączyć na 2 sposoby:</p>
              <ul>
                <li>
                  w karcie klienta w sekcji „Monitorowanie” – za pomocą suwaka,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2a.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  na liście klientów – zbiorczo, za pomocą akcji grupowej „Wyłącz monitorowanie”, po uprzednim
                  zaznaczeniu klientów,
                  <div className={styles.tutorialImageWrapper}>
                    <img
                      src={require('../../../assets/images/tutorial/3-2c.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  za pomocą odpowiedniego kodu operacji w ramach importu pliku CSV (więcej informacji na ten temat
                  znajduje się w Samouczku w punkcie „7.6. Wykonywanie działań masowych za pomocą kodów operacji przy
                  imporcie pliku CSV.”).
                </li>
              </ul>
              <p>
                Wyłączając monitorowanie można też wskazać konkretną datę przyszłą (np. za tydzień), po której
                monitorowanie zostanie wyłączone i informacje przestaną być odświeżane.
              </p>
              <p>
                Wyłączenie monitorowania powoduje zaprzestanie wyświetlania w serwisie RiskRadar informacji o
                monitorowanym kliencie. Skutkiem wyłączenia monitorowania jest usunięcie historii monitorowania klienta
                z RiskRadar, w tym usunięcie informacji o zdarzeniach w KRD BIG S.A. (o ile takie miały miejsce),
                informacji o poziomie zadłużenia w KRD BIG S.A., informacji o kategorii wiarygodności płatniczej.
              </p>
              <p>
                Jeśli próba wyłączenia monitorowania ma miejsce po godzinie 23:00 to pojawi się komunikat o braku
                możliwości wykonania operacji w tym dniu oraz propozycja wyłączenia monitorowania w kolejnym dniu. Po
                wyrażeniu zgody, monitorowanie zostanie wyłączone w kolejnym dniu.
              </p>
              <p>
                W czasie pomiędzy wyłączeniem monitorowania danego numeru NIP a końcem doby (tj. 23:59), w której
                nastąpiło wyłączenie monitorowania, prezentowany jest komunikat „Trwa wyłączanie monitorowania.
                Monitorowanie zostanie zatrzymane z końcem doby, w której zlecono jego wyłączenie.”. W tym czasie nie
                jest możliwe ponowne włączenie usługi monitorowania dla tego numeru NIP.
              </p>
              <p>
                Od razu po wyłączeniu monitorowania danego klienta filtr „Monitorowanie klienta” w module filtrowania
                traktuje tego klienta tak jakby miał status monitorowania wyłączony (informacje z monitorowania nie są
                bowiem już dostępne w RiskRadar).
              </p>
              <p>
                W okresie pomiędzy włączeniem a uruchomieniem monitorowania można wykonać operację jego wyłączenia,
                jednak takie działanie nie cofa licznika usług. Innymi słowy, jeśli włączone zostanie monitorowanie np.
                o 13:20, a następnie wyłączone o 13:25 to usługa zostanie naliczona (w ramach opłaty miesięcznej lub
                jako przekroczenie limitu).
              </p>
              <p>
                W przypadku ponownego włączenia monitorowania dla danego klienta dane historyczne (z wcześniej
                włączonego monitorowania) nie są pokazywane.
              </p>
              <p>
                Więcej informacji na temat licznika usług znajduje się w Samouczku w punkcie „6.4. Jak działa licznik
                usług?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jakie skutki dla klienta powoduje włączenie jego monitorowania w RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Skutkiem włączenia monitorowania klienta w RiskRadar jest odnotowanie zapytania w Rejestrze Zapytań KRD
                BIG S.A. (zgodnie z ustawą). Każdy podmiot, który na swój wniosek sprawdzi Rejestr Zapytań, uzyska
                wiedzę o tym, kto i kiedy go sprawdzał oraz jaką informację uzyskał. Jeśli podmiot ten posiada płatną
                usługę automonitorowania w KRD BIG S.A. to otrzyma automatyczne powiadomienie o wykonanym przez daną
                firmę odpytaniu o jego dane w KRD BIG S.A.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Analiza wiarygodności płatniczej',
    subsections: [
      {
        name: 'Czym jest kategoria wiarygodności płatniczej w ramach analizy wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Wyznaczanie kategorii wiarygodności płatniczej (tzw. scoringu) w ramach analizy wiarygodności płatniczej
                jest przeprowadzana przez KRD BIG S.A. i realizowane w ramach usługi monitorowania klienta w RiskRadar.
                Polega na analizie podmiotu pod kątem jego wiarygodności płatniczej, a następnie nadaniu mu jednej z
                następujących kategorii wiarygodności płatniczej:
              </p>
              <ul>
                <li>A – Najwyższa ocena,</li>
                <li>B – Bardzo wysoka ocena,</li>
                <li>C – Wysoka ocena,</li>
                <li>D – Średnia ocena,</li>
                <li>E – Niska ocena,</li>
                <li>F – Bardzo niska ocena,</li>
                <li>G – Najniższa ocena,</li>
                <li>H – Istotne zadłużenie w systemie KRD BIG S.A.</li>
              </ul>
              <p>
                W przypadku podmiotu z przychodem rocznym do 100 milionów złotych (zgodnie z ostatnim dostępnym
                sprawozdaniem finansowym) lub brakiem tej informacji, dana kategoria (od A do G) określa poziom
                prawdopodobieństwa wpisania podmiotu do systemu KRD BIG S.A. na kwotę wyższą niż 2000 złotych. W
                przypadku podmiotu z przychodem rocznym co najmniej 100 milionów złotych dana kategoria (od A do G)
                określa poziom prawdopodobieństwa wpisania podmiotu do systemu KRD BIG S.A. na kwotę wyższą niż 0,05%
                przychodu.
              </p>
              <p>
                Gdy zwiększa się ryzyko dopisania podmiotu do systemu KRD BIG S.A., to jednocześnie obniża się jego
                wiarygodność płatnicza. Kategorię A posiadają podmioty, dla których ryzyko wynosi około 0,5%, natomiast
                dla podmiotów z kategorii G ryzyko wynosi ponad 32%.
              </p>
              <p>
                Kategoria H oznacza, iż dany podmiot widnieje w systemie KRD BIG S.A. na kwotę wyższą niż 2.000 złotych
                w przypadku, gdy jego przychód roczny wynosi do 100 mln złotych (zgodnie z ostatnim dostępnym
                sprawozdaniem finansowym) lub nie jest dostępna informacja o przychodzie. Dla podmiotów, których
                przychód roczny wynosi co najmniej 100 mln złotych kategoria H przypisywana jest w sytuacji, gdy kwota
                dopisana do systemu KRD BIG S.A. przekracza 0,05% jego przychodu rocznego.
              </p>
              <p>
                Na losowej próbie ponad 1 mln NIP ryzyko dopisania do systemu KRD BIG S.A. dla poszczególnych grup
                kształtowało się następująco (według analiz KRD BIG S.A.):
              </p>
              <ul>
                <li>A – 0,5%</li>
                <li>B – 1,4%</li>
                <li>C – 2,6%</li>
                <li>D – 4,3%</li>
                <li>E – 6,5%</li>
                <li>F – 11,4%</li>
                <li>G – 32,1%</li>
              </ul>
              <p>
                W niektórych przypadkach wyznaczanie kategorii wiarygodności płatniczej nie jest wykonywane. Zwracany
                jest wówczas powód braku wyznaczenia kategorii.
              </p>
              <p>
                Do wyznaczenia kategorii wiarygodności płatniczej wykorzystywane są unikalne zasoby bazodanowe KRD BIG
                S.A. oraz rejestry publiczne CEIDG, KRS, REGON. Wiarygodność płatnicza wyznaczana jest w oparciu o
                metody uczenia maszynowego. Wynik analizy cechuje się wysokimi współczynnikami jakości, co zostało
                sprawdzone na puli około 1 mln pomiotów wybranych losowo (według analiz KRD BIG S.A.).
              </p>
              <p>Wyznaczanie kategorii wiarygodności płatniczej przeprowadzana jest w następujący sposób:</p>
              <ol>
                <li>Pobranie/aktualizacja danych z rejestrów publicznych CEIDG, KRS, REGON.</li>
                <li>Oczyszczenie i standaryzacja pobranych danych.</li>
                <li>
                  W oparciu o informacje z rejestrów publicznych oraz zasoby bazodanowe KRD BIG S.A. utworzenie
                  charakterystyk mających wpływ na wiarygodność płatniczą.
                </li>
                <li>
                  Przy pomocy algorytmu uczenia maszynowego wyznaczenie prawdopodobieństwa dopisania podmiotu do systemu
                  KRD BIG S.A.{' '}
                </li>
                <li>
                  Przypisanie kategorii wiarygodności płatniczej (uzależnione od ryzyka wyznaczonego w punkcie 4).
                </li>
              </ol>
              <p>
                Analiza wiarygodności płatniczej dłużnika niebędącego konsumentem przeprowadzana jest na podstawie
                artykułu 31a ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych. Wynik
                analizy jest udostępniany bez konieczności pozyskiwania zgody od analizowanego podmiotu. Analizie
                podlegają podmioty niebędące konsumentami np. przedsiębiorstwa mikro, średnie i duże instytucje. Analiza
                wiarygodności płatniczej jest dokonywana dla polskich podmiotów (tj. posiadających numer NIP), nie jest
                dokonywana dla podmiotów zagranicznych.
              </p>
              <p>
                Co do zasady, bezpieczniej jest współpracować z podmiotami posiadającymi wyższą kategorię wiarygodności
                płatniczej (na przykład kategorię „A”), a zachować szczególną ostrożność współpracując z podmiotami
                posiadającymi niższą kategorię wiarygodności płatniczej (na przykład kategorię „H”).
              </p>
              <p>
                Analiza wiarygodności płatniczej bazuje na analizie statystycznej, co oznacza, że w grupie podmiotów z
                wyższą kategorią mogą sporadycznie trafić się podmioty niewiarygodne, i na odwrót – w grupie podmiotów z
                niższą kategorią mogą sporadycznie trafić się podmioty wiarygodne. Z tego względu wynik analizy
                wiarygodności płatniczej należy traktować raczej jako pewną wskazówkę w procesie decyzyjnym, a nie
                gwarancję wiarygodności podmiotu (lub jej braku).
              </p>
              <p>W następujących przypadkach:</p>
              <ul>
                <li>podmiot jednocześnie prowadzi działalność gospodarczą oraz gospodarstwo rolne,</li>
                <li>podmiot jednocześnie prowadzi działalność gospodarczą oraz jest wspólnikiem w spółce cywilnej,</li>
              </ul>
              <p>analiza wiarygodności płatniczej jest wyznaczana dla działalności gospodarczej.</p>
              <p>Dodatkowe informacje dostępne są na stronie:</p>
              <a
                href="https://kaczmarskigroup.pl/kategoria-wiarygodnosci-platniczej-szczegoly"
                rel="noopener noreferrer"
                target="_blank">
                https://kaczmarskigroup.pl/kategoria-wiarygodnosci-platniczej-szczegoly
              </a>
            </div>
          );
        }
      },
      {
        name: 'Czym jest limit kupiecki w ramach analizy wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Usługa limitu kupieckiego polega na wyznaczeniu maksymalnej kwoty, wyrażonej w PLN, do jakiej – na
                podstawie analizy statystycznej – rekomendowana jest sprzedaż kontrahentowi usług lub towarów z
                odroczonym terminem płatności.
              </p>
              <p>
                Limit ten może być ustalany jako kwota maksymalna na jednorazowe zamówienie lub wszystkie zamówienia w
                określonym czasie, obejmującym na przykład miesiąc lub rok. Obliczenia opierają się na założeniu, że
                oceniany podmiot ma do 8 dostawców (aktualnie przyjmowana wartość znajduje się na stronie{' '}
                <a
                  href="https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej"
                  rel="noopener noreferrer"
                  target="_blank">
                  https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej
                </a>
                ), którzy dostarczają usługi lub towary w podobnej kwocie i w tym samym czasie ze średnim terminem
                płatności wynoszącym 60 dni.
              </p>
              <p>W ramach usługi limitu kupieckiego prezentowane są następujące informacje:</p>
              <ul>
                <li>rekomendowana kwota limitu kupieckiego wyznaczona dla danego kontrahenta,</li>
                <li>data wyznaczenia limitu kupieckiego,</li>
                <li>model, na podstawie którego została wyznaczona kwota limitu.</li>
              </ul>
              <p>
                Kwota limitu kupieckiego stanowi rekomendację i nie należy jej utożsamiać z decyzją kredytową. Decyzja
                ta zawsze winna być podjęta przez dostawcę towarów i usług. Wynik kalkulacji limitu kupieckiego należy
                traktować raczej jako pewną wskazówkę w procesie decyzyjnym, a nie gwarancję zdolności do spłaty
                zobowiązań na kwotę wskazaną w rekomendowanej wysokości limitu kupieckiego.
              </p>
              <p>
                Wyznaczenie limitu kupieckiego jako elementu analizy wiarygodności płatniczej podmiotu niebędącego
                konsumentem jest wykonywane przez KRD BIG S.A. na podstawie artykułu 31a ustawy z dnia 9 kwietnia 2010
                r. o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych. Wyznaczenie limitu
                kupieckiego i udostępnienie jego wyniku nie wymaga zgody ocenianego podmiotu.
              </p>
              <p>
                Wyznaczanie limitu kupieckiego może być wykonane wyłącznie dla podmiotów identyfikowanych na podstawie
                NIP będących przedsiębiorcami (przedsiębiorstwa mikro, średnie i duże), wpisanymi do rejestrów
                publicznych (KRS, CEIDG) oraz dla spółek cywilnych. Jeżeli podmiot nie spełnia wszystkich powyższych
                warunków (np. rolnik nieprowadzący działalności gospodarczej), to limit kupiecki nie zostanie
                wyznaczony. Wyznaczenie limitu kupieckiego nie jest wykonywane dla konsumentów.
              </p>
              <p>Limit kupiecki oraz status jego dostępności może być wyznaczony w oparciu o jeden z dwóch modeli:</p>
              <ul>
                <li>
                  Model 1 – limit wyznaczony na podstawie danych umożliwiających ocenę skali działalności i ryzyka
                  niewypłacalności podmiotu, z uwzględnieniem danych ze sprawozdań finansowych (M1),
                </li>
                <li>
                  Model 2 – limit wyznaczony na podstawie danych umożliwiających ocenę skali działalności i ryzyka
                  niewypłacalności podmiotu (M2).
                </li>
              </ul>
              <p>Sposób wyznaczenia wartości limitu dla powyższych modeli jest następujący:</p>
              <ol>
                <li>
                  Pobranie/aktualizacja danych na temat analizowanego podmiotu niebędącego konsumentem z rejestrów
                  publicznych CEIDG, KRS, REGON.
                </li>
                <li>
                  Pobranie/aktualizacja danych pochodzących ze sprawozdań finansowych w KRS, o ile zostały złożone.
                </li>
                <li>Oczyszczenie i standaryzacja pobranych danych.</li>
                <li>
                  Utworzenie:
                  <ol className={styles.tableOrderedList}>
                    <li>
                      <span className={styles.counter}>a)</span> w przypadku Modelu 1 – wskaźników finansowych
                      obrazujących poziom zadłużenia bilansowego, efektywności gospodarowania (rotacji należności i
                      zobowiązań), płynności, rentowności oraz stabilności finansowej.
                    </li>
                    <li>
                      <span className={styles.counter}>b)</span> w przypadku Modelu 2 – charakterystyk mających wpływ na
                      skalę działalności podmiotu oraz wiarygodność płatniczą.
                    </li>
                  </ol>
                </li>
                <li>
                  Przypisanie analizowanemu podmiotowi rekomendowanej wysokości limitu kupieckiego lub wskazanie
                  przyczyny braku jego wyznaczenia.
                </li>
                <li>
                  Podmioty o wysokim poziomie kategorii wiarygodności płatniczej (od A do C), u których istnieją, po
                  ocenie w Modelu 1, wątpliwości co do dobrej kondycji finansowej lub podmioty, których sprawozdania
                  finansowe nie są dostępne mają wyznaczony limit kupiecki w oparciu o Model 2.
                </li>
              </ol>
              <p>
                Wyznaczanie limitu kupieckiego bazuje na analizie statystycznej, co oznacza, że (zwłaszcza w przypadku
                wyznaczenia limitu kupieckiego w Modelu 2) mogą trafić się podmioty, dla których kwota limitu jest
                niższa od rzeczywistej zdolności podmiotu do spłaty zobowiązań. Zdecydowanie rzadziej, ze względu na
                przyjęte podejście ostrożnościowe, zdarzyć się mogą przypadki odwrotne, tzn. kwota wyznaczonego limitu
                kupieckiego może być wyższa od rzeczywistej zdolności podmiotu.
              </p>
              <p>Przykładowe dane brane pod uwagę przy wyznaczeniu limitu kupieckiego w Modelu 1:</p>
              <ul>
                <li>kategoria wiarygodności płatniczej,</li>
                <li>skala działalności mierzona poziomem średniomiesięcznych obrotów z działalności podstawowej,</li>
                <li>poziom osiąganych zysków (zysk netto oraz EBITDA),</li>
                <li>stan bilansowy środków pieniężnych oraz aktywów płynnych (należności krótkoterminowe, zapasy),</li>
                <li>poziom bilansowy zobowiązań długo- i krótkoterminowych,</li>
                <li>okres rotacji należności i zobowiązań handlowych,</li>
                <li>poziom kapitału obrotowego,</li>
                <li>wskaźnik płynności szybkiej oraz bieżącej,</li>
                <li>
                  wskaźnik zadłużenia, tj. relacji zobowiązań bilansowych do zysku netto i EBITDA oraz relacji
                  zobowiązań do posiadanych kapitałów własnych.
                </li>
              </ul>
              <p>Przykładowe dane brane pod uwagę przy wyznaczaniu limitu kupieckiego w Modelu 2:</p>
              <ul>
                <li>kategoria wiarygodności płatniczej,</li>
                <li>
                  czas prowadzenia działalności, mierzony od daty rejestracji podmiotu, z wyłączeniem okresów
                  zawieszenia działalności,
                </li>
                <li>forma prawna,</li>
                <li>główne PKD.</li>
              </ul>
              <p>Model 1 i Model 2 są stosowane dla następujących przypadków:</p>
              <ol>
                <li>
                  W Modelu 1 wyznaczana jest rekomendowana wysokość limitu kupieckiego (jednak nie wyższa niż 3.000.000
                  PLN, a limit nie przekroczy 120% średniomiesięcznych obrotów analizowanego podmiotu za ostatni
                  zamknięty rok obrachunkowy) w odniesieniu do podmiotu, który na moment jego wyznaczania posiada
                  opublikowane w KRS aktualne, tzn. nie starsze niż 3 lata (licząc od dnia zamknięcia roku
                  obrachunkowego), sprawozdanie finansowe, z zastrzeżeniem przypadku określonego w punkcie 2b poniżej.
                </li>
                <li>
                  W Modelu 2 wyznaczana jest rekomendowana wysokość limitu kupieckiego (nie wyższa jednak niż 50.000
                  PLN) w odniesieniu do podmiotu, który na moment jego wyznaczania spełnia co najmniej jedno z
                  poniższych:
                  <ol className={styles.tableOrderedList}>
                    <li>
                      <span className={styles.counter}>a)</span> nie posiada opublikowanego w KRS sprawozdania
                      finansowego,
                    </li>
                    <li>
                      <span className={styles.counter}>b)</span> posiada opublikowane w KRS nie starsze niż 3 lata
                      (licząc od dnia zamknięcia roku obrachunkowego) sprawozdanie finansowe, jednak w oparciu o Model 1
                      rekomendowany limit kupiecki wynosiłby zero, a jednocześnie kategoria wiarygodności płatniczej
                      takiego podmiotu wynosi od A do C,
                    </li>
                    <li>
                      <span className={styles.counter}>c)</span> prowadzi jednoosobową działalność gospodarczą
                      potwierdzoną wpisem w CEIDG,
                    </li>
                    <li>
                      <span className={styles.counter}>d)</span> nie są spełnione warunki dla wyznaczenia limitu w
                      Modelu 1.
                    </li>
                  </ol>
                </li>
              </ol>
              <p>
                Wskazane powyżej kwoty (tj. 3.000.000 PLN dla Modelu 1 oraz 50.000 PLN dla Modelu 2) stanowią maksymalne
                wartości przyjęte w Modelach 1 i 2 na potrzeby wyznaczenia limitu. Oznacza to, że niezależnie od
                okoliczności model ograniczy się do ustalonej, ściśle określonej kwoty jako górnej granicy dostępnego
                limitu, nawet jeśli w szczególnych przypadkach możliwym byłoby wyznaczenie wyższego Limitu Kupieckiego.
              </p>
              <p>
                W niektórych przypadkach wyznaczanie limitu kupieckiego nie jest wykonywane. Zwracany jest wówczas powód
                braku wyznaczenia limitu.
              </p>
              <p>W następujących przypadkach:</p>
              <ul>
                <li>podmiot jednocześnie prowadzi działalność gospodarczą oraz gospodarstwo rolne,</li>
                <li>podmiot jednocześnie prowadzi działalność gospodarczą oraz jest wspólnikiem w spółce cywilnej,</li>
              </ul>
              <p>analiza wiarygodności płatniczej jest wyznaczana dla działalności gospodarczej.</p>
              <p>Dodatkowe informacje dostępne są na stronie:</p>
              <a
                href="https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej"
                rel="noopener noreferrer"
                target="_blank">
                https://kaczmarskigroup.pl/limit-kupiecki-w-ramach-analizy-wiarygodnosci-platniczej
              </a>
            </div>
          );
        }
      },
      {
        name: 'Jak włączyć pobieranie informacji o kategorii wiarygodności płatniczej i limicie kupieckim?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Kategoria wiarygodności płatniczej oraz limit kupiecki są wyznaczane w ramach analizy wiarygodności
                płatniczej. Analiza ta jest jednym z trzech powiązanych ze sobą elementów usługi monitorowania w
                RiskRadar. Włączenie pobierania informacji o kategorii wiarygodności płatniczej oraz limicie kupieckim
                wymaga więc włączenia usługi monitorowania w RiskRadar.
              </p>
              <p>
                Jeśli dana firma posiada dostęp do RiskRadar w wersji bez usługi limitu kupieckiego, informacje o
                limicie kupieckim nie będą prezentowane. Aktywacja tej usługi wymaga zmiany umowy – w tym celu należy
                skontaktować się z opiekunem firmy w KG.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć pobieranie informacji o kategorii wiarygodności płatniczej i limicie kupieckim?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Kategoria wiarygodności płatniczej oraz limit kupiecki są wyznaczane w ramach analizy wiarygodności
                płatniczej. Analiza ta jest jednym z trzech powiązanych ze sobą elementów usługi monitorowania w
                RiskRadar. Wyłączenie pobierania informacji o kategorii wiarygodności płatniczej oraz limicie kupieckim
                wymaga więc wyłączenia usługi monitorowania w RiskRadar.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Czy i w jakich obszarach podmiot ma wpływ na wynik analizy wiarygodności płatniczej w postaci kategorii wiarygodności płatniczej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Przy wykonywaniu przez KRD BIG S.A. analizy wiarygodności płatniczej w postaci kategorii wiarygodności
                płatniczej podmiotu niebędącego konsumentem branych pod uwagę jest kilkaset informacji o podmiocie
                gospodarczym i jego otoczeniu z następujących źródeł:
              </p>
              <ul>
                <li>CEIDG,</li>
                <li>REGON,</li>
                <li>KRS,</li>
                <li>KRD BIG S.A.</li>
              </ul>
              <p>
                Tylko w przypadku oceny „H” istnieje jednoznaczna odpowiedź na to, co należy zrobić, by zmienić ocenę na
                inną (wyższą); jest nią usunięcie zobowiązań figurujących w KRD BIG S.A., np. na skutek spłaty. W
                przypadku kategorii od A do G wpływ na daną ocenę ma najczęściej wiele czynników, również tych, na które
                sam podmiot nie ma wpływu lub jest on ograniczony.
              </p>
              <p className={styles.textUnderline}>
                Przykładowe informacje, na które podmiot oceniany ma bezpośredni wpływ:
              </p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.th}>Cecha / Kategoria</th>
                  <th className={styles.th}>Co ma wpływ na ocenę?</th>
                  <th className={styles.th}>Kiedy ocena może się podwyższyć?</th>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące kapitału</td>
                  <td className={styles.td}>Wielkość kapitału</td>
                  <td className={styles.td}>Jeżeli zwiększy się kapitał podmiotu (dotyczy spółek prawa handlowego)</td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące przychodu rocznego</td>
                  <td className={styles.td}>Wielkość przychodu rocznego</td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy przychód roczny oraz dostarczy terminowo swoje sprawozdanie finansowe do
                    Repozytorium Dokumentów Finansowych eKRS.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące rentowności</td>
                  <td className={styles.td}>
                    Rentowność na podstawie sprawozdania finansowego (rachunek zysków i strat)
                  </td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy rentowność oraz dostarczy terminowo swoje sprawozdanie finansowe do
                    Repozytorium Dokumentów Finansowych eKRS.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>
                    Widoczność podmiotu na rynku, rozumiana jako zakres wypełnienia danych kontaktowych w wymienionych
                    powyżej rejestrach publicznych
                  </td>
                  <td className={styles.td}>Rozpoznawalność na rynku.</td>
                  <td className={styles.td}>
                    Jeżeli podmiot zwiększy swoją widoczność w Internecie, np. założy stronę internetową jeżeli jej
                    dotąd nie posiadał, udostępni dane kontaktowe dla potencjalnych klientów w rejestrach publicznych.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Aktualne informacje gospodarcze</td>
                  <td className={styles.td}>Brak negatywnych wpisów w KRD BIG S.A.</td>
                  <td className={styles.td}>
                    Jeżeli podmiot: reguluje swoje zobowiązania w terminie, przez co nie trafia do KRD BIG S.A., nie
                    podejmuje ryzykownej współpracy z mało wiarygodnymi kontrahentami (weryfikując ich np. w KRD BIG
                    S.A.).
                  </td>
                </tr>
              </table>
              <p className={styles.textUnderline}>
                Przykładowe informacje, na które podmiot oceniany nie ma wpływu lub jest on ograniczony:
              </p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.th}>Cecha / Kategoria</th>
                  <th className={styles.th}>Co ma wpływ na ocenę?</th>
                  <th className={styles.th}>Kiedy ocena może się podwyższyć?</th>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące działalności podmiotu na rynku</td>
                  <td className={styles.td}>Czas działania na rynku</td>
                  <td className={styles.td}>Jeżeli podmiot dłużej będzie działał na rynku.</td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące branży podmiotu</td>
                  <td className={styles.td}>
                    Częstotliwość występowania problemów płatniczych w branży podmiotu, szczególnie w jego regionie
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w branży podmiotu ulegnie poprawie, tj. rzadziej występować w niej będą problemy
                    płatnicze (w szczególności w jego regionie).
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące regionu, w którym działa podmiot</td>
                  <td className={styles.td}>
                    Częstotliwość występowania problemów płatniczych w regionie, w którym działa podmiot
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w regionie podmiotu ulegnie poprawie, tj. rzadziej występować w nim będą problemy
                    płatnicze (w szczególności w jego branży).
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>Informacje dotyczące konkurencji podmiotu</td>
                  <td className={styles.td}>Liczba podmiotów konkurencyjnych</td>
                  <td className={styles.td}>Jeżeli w regionie podmiotu dla jego branży zmniejszy się konkurencja.</td>
                </tr>
                <tr>
                  <td className={styles.td}>
                    Informacje dotyczące zobowiązań znajdujących się w KRD BIG S.A. w danej branży i regionie
                  </td>
                  <td className={styles.td}>
                    Informacje dotyczące długości okresu, w jakim zobowiązania w danym segmencie historycznie znajdowały
                    się w KRD BIG S.A.
                  </td>
                  <td className={styles.td}>
                    Jeżeli sytuacja w branży podmiotu ulegnie poprawie, tj. problemy płatnicze będą występowały u
                    podmiotów krócej/będą szybciej spłacane (w szczególności w jego regionie).
                  </td>
                </tr>
              </table>
            </div>
          );
        }
      },
      {
        name: 'Jak interpretować poszczególne kategorie wiarygodności płatniczej i przenieść je na działania w swoim biznesie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Ze względu na to, że kategoria wiarygodności płatniczej w ramach analizy wiarygodności płatniczej
                określa poziom prawdopodobieństwa (ryzyka) wpisania podmiotu do rejestru dłużników KRD BIG S.A. – a nie
                zapewnienie, że tak się wydarzy – każda firma powinna wypracować własny sposób interpretacji wyniku, a
                przede wszystkim działań, które należy podjąć, w zależności od otrzymanej przed rozpoczęciem lub w
                trakcie współpracy oceny podmiotu. Sposób ten powinien być dostosowany do rodzaju działalności i
                aktualnej sytuacji danej firmy. Może być to jedno podejście dla wszystkich kontrahentów, ale też może
                być zróżnicowane w zależności od ich rodzaju czy też wielkości transakcji. Poniżej znajdują się
                przykładowe działania, jakie można podjąć w poszczególnych kategoriach wiarygodności płatniczej. Należy
                jednak pamiętać, że każdorazowo należy samodzielnie ocenić i podjąć decyzję w oparciu o szereg
                zróżnicowanych informacji, a wykonana przez KRD BIG S.A. analiza stanowi jedynie wsparcie w podjęciu tej
                decyzji przez daną firmę.
              </p>
              <p className={styles.textUnderline}>Przykładowe działania dotyczące nowego kontrahenta</p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.scoringTh}>Kategoria</th>
                  <th className={styles.scoringTh}>Status</th>
                  <th className={styles.scoringTh}>
                    Propozycja przykładowych działań dodatkowych do ograniczenia ryzyka
                  </th>
                </tr>
                <tr>
                  <td className={styles.td}>A</td>
                  <td className={styles.td}>Kontrahent z grupy najmniej ryzykownych do współpracy</td>
                  <td className={styles.td}>
                    Brak działań – współpraca z tym kontrahentem jest obecnie najmniej ryzykowna, jak i z dużym
                    prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej przyszłości.{' '}
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>B, C</td>
                  <td className={styles.td}>Kontrahent z grupy niskiego ryzyka do współpracy</td>
                  <td className={styles.td}>
                    Brak działań, jednak, jeśli to nie jest jednorazowa współpraca zaleca się stałe i cykliczne
                    weryfikowanie oceny, np. raz na miesiąc.
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>D, E</td>
                  <td className={styles.td}>Kontrahent z grupy średniego ryzyka – zachowanie ostrożności</td>
                  <td className={styles.td}>
                    Sprawdzić w Raporcie o kliencie:
                    <ul>
                      <li>
                        wynik finansowy z ostatniego dostępnego sprawozdania finansowego (jeśli kontrahent nie jest
                        jednoosobową działalnością gospodarczą); wątpliwości może budzić brak dostępnego w KRS
                        sprawozdania finansowego za ostatni okres rozliczeniowy,
                      </li>
                      <li>sprawdzić, czy kontrahent posiada informacje pozytywne.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>F, G</td>
                  <td className={styles.td}>Kontrahent z grupy najwyższego ryzyka – zalecana pogłębiona analiza</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Unikać transakcji z odroczonym terminem płatności – gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, polecić mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                      <li>
                        Sprawdzić w Raporcie o kliencie:
                        <ol className={styles.tableOrderedList}>
                          <li>
                            <span className={styles.counter}>1)</span> sytuację finansową kontrahenta w KRS,
                          </li>
                          <li>
                            <span className={styles.counter}>2)</span> jeśli kontrahent nie jest jednoosobową
                            działalnością gospodarczą, pobrać wynik finansowy z ostatniego dostępnego sprawozdania
                            finansowego i porównać go do poprzednich lat; wątpliwości może budzić brak dostępnego w KRS
                            sprawozdania finansowego za ostatni okres rozliczeniowy,
                          </li>
                          <li>
                            <span className={styles.counter}>3)</span> zobaczyć powiązania kontrahenta – możliwe, że
                            rozpoznani zostaną wśród nich stali klienci, którzy będą w stanie poświadczyć jego
                            wiarygodność.
                          </li>
                        </ol>
                      </li>
                      <li>Jeśli to nie jednorazowa transakcja, stale monitorować kontrahenta w RiskRadar.</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>H </td>
                  <td className={styles.td}>Kontrahent zadłużony w KRD BIG S.A. </td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Unikać transakcji z odroczonym terminem płatności – gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, poleć mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                      <li>Jeśli to nie jednorazowa transakcja, stale monitorować kontrahenta w RiskRadar.</li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p className={styles.textUnderline}>Przykładowe działania dotyczące stałego kontrahenta</p>
              <table className={styles.table}>
                <tr>
                  <th className={styles.scoringTh}>Kategoria</th>
                  <th className={styles.scoringTh}>Status</th>
                  <th className={styles.scoringTh}>
                    Propozycja przykładowych działań dodatkowych do ograniczenia ryzyka
                  </th>
                </tr>
                <tr>
                  <td className={styles.td}>A</td>
                  <td className={styles.td}>Kontrahent z grupy najmniej ryzykownych do kontynuacji współpracy</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Brak natychmiastowych dodatkowych działań (współpraca z tym kontrahentem jest obecnie najmniej
                        ryzykowna, jak i z dużym prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej
                        przyszłości).
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>B, C</td>
                  <td className={styles.td}>Kontrahent z grupy niskiego ryzyka do kontynuacji współpracy</td>
                  <td className={styles.td}>
                    <ul>
                      <li>
                        Brak natychmiastowych dodatkowych działań (współpraca z tym kontrahentem jest obecnie mało
                        ryzykowna, jak i z dużym prawdopodobieństwem będzie on nadal wiarygodnym partnerem w najbliższej
                        przyszłości).
                      </li>
                      <li>
                        Ze względu na to, że ocena podmiotu może się zmienić w czasie – śledzenie jego sytuacji poprzez
                        stałe i cykliczne weryfikowanie oceny np. raz na miesiąc oraz stałe monitorowanie kontrahenta w
                        RiskRadar.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>D, E</td>
                  <td className={styles.td}>Kontrahent z grupy średniego ryzyka – zachowanie ostrożności</td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwrócić uwagę czy dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień.</li>
                      <li>
                        Ze względu na to, że sytuacja może się zmienić w czasie, stale monitorować kontrahenta w
                        RiskRadar.
                      </li>
                      <li>
                        Jeśli kontrahent nie jest jednoosobową działalnością gospodarczą, upewnić się, że jest w dobrej
                        kondycji finansowej, sprawdzając w Raporcie jego wynik finansowy z ostatniego dostępnego
                        sprawozdania finansowego; wątpliwości może budzić brak dostępnego w KRS sprawozdania finansowego
                        za ostatni okres rozliczeniowy.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>F, G </td>
                  <td className={styles.td}>Kontrahent z grupy najwyższego ryzyka – zalecana pogłębiona analiza </td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwrócić uwagę czy dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień.</li>
                      <li>Stale monitorować kontrahenta w RiskRadar.</li>
                      <li>
                        Upewnić się, że kondycja finansowa kontrahenta się nie pogarsza – sprawdzić w Raporcie:
                        <ol className={styles.tableOrderedList}>
                          <li>
                            <span className={styles.counter}>1)</span> sytuację finansową kontrahenta w KRS,
                          </li>
                          <li>
                            <span className={styles.counter}>2)</span> wynik finansowy z ostatniego dostępnego
                            sprawozdania finansowego i porównać go do poprzednich lat o ile nie jest to jednoosobowa
                            działalność gospodarcza; wątpliwości może budzić brak dostępnego w KRS sprawozdania
                            finansowego za ostatni okres rozliczeniowy.
                          </li>
                        </ol>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td className={styles.td}>H</td>
                  <td className={styles.td}>Kontrahent zadłużony w KRD BIG S.A.</td>
                  <td className={styles.td}>
                    <ul>
                      <li>Zwrócić uwagę czy dotychczasowe transakcje z kontrahentem przebiegały bez opóźnień.</li>
                      <li>Sprawdzić w Raporcie jaka jest sytuacja finansowa kontrahenta w KRS.</li>
                      <li>
                        Unikać transakcji z odroczonym terminem płatności – gdy kontrahent nie będzie w stanie zapłacić
                        za usługę od razu, polecić mu rozłożenie{' '}
                        <a
                          className={styles.blueLink}
                          href="https://fakturatka.pl/sprzedawaj-na-raty"
                          rel="noopener noreferrer"
                          target="_blank">
                          płatności na raty
                        </a>
                        .
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
              <p className={styles.textUnderline}>Zastrzeżenie prawne</p>
              <p>
                KRD BIG S.A. nie ponosi odpowiedzialności z tytułu decyzji podjętych na podstawie danych przekazanych w
                ramach wykonania usługi. Dane te nie stanowią opinii KRD BIG S.A. w przedmiocie współpracy ze wskazanym
                w zleceniu podmiotem i nie mogą stanowić podstawy dla roszczeń danej firmy korzystającej z RiskRadar
                kontrahenta danej firmy, którego dotyczyło zlecenie wykonania usługi, w stosunku do KRD BIG S.A. z
                tytułu podjęcia współpracy z przedsiębiorcą, którego dotyczyło zlecenie. Odpowiedzialność z tytułu
                decyzji podjętych na podstawie wykonanej przez KRD BIG S.A. usługi ponosi względem swoich kontrahentów
                wyłącznie dana firma korzystająca z RiskRadar.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Usługa raportu o kliencie',
    subsections: [
      {
        name: 'Na czym polega usługa raportu o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W ramach usługi „Raport KG” świadczonej przez Kaczmarski Group sp.j. istnieje możliwość zapoznania się
                ze szczegółowym raportem na temat klienta. Raport ten opiera się m.in. na danych z: KRD BIG S.A.,
                wybranych giełd wierzytelności, Bazy Rzetelnych Firm, tzw. Białej Listy, KRS (w tym US i ZUS), CEIDG,
                UOKiK, KNF. Raport KG zawiera także wyniki analizy wiarygodności płatniczej (ujawnione KG przez KRD BIG
                S.A.) w postaci kategorii wiarygodności płatniczej oraz kwocie limitu kupieckiego. Raport KG jest
                dostępny w formie pliku PDF i zwykle zawiera od kilku do kilkunastu stron. Raporty KG są dostępne tylko
                dla tych klientów, dla których zostaną pobrane.
              </p>
              <p>
                Pobrany Raport KG jest dostępny do podglądu w RiskRadar przez okres 7 dni od dnia jego pobrania. Po 7
                dniach zalecane jest pobranie aktualnego Raportu KG z uwagi na możliwość dezaktualizacji informacji.
                Chcąc mieć dostęp do Raportu KG dłużej niż 7 dni, można zapisać plik PDF na swoim urządzeniu.
              </p>
              <p>
                Wszystkie Raporty KG pobrane przez użytkowników danej firmy są dostępne w RiskRadar przez 7 dni. Oznacza
                to, że jeśli dany użytkownik pobrał aktualny Raport KG o danym kliencie, to inni użytkownicy z tej samej
                firmy mający dostęp do RiskRadar także mogą się z nim zapoznać bez dodatkowego naliczania usługi. Lista
                wszystkich wygenerowanych Raportów KG dostępna jest w zakładce „Historia pobranych raportów” (więcej
                informacji na ten temat znajduje się w Samouczku w punkcie „11.1. Co zawiera zakładka „Historia
                pobranych raportów”?”.
              </p>
              <p>Raport KG o kliencie można pobrać:</p>
              <ul>
                <li>w serwisie RiskRadar w zakładce „Lista klientów”,</li>
                <li>w serwisie RiskRadar w karcie danego klienta,</li>
                <li>z raportu cyklicznego zdefiniowanego w „Kreatorze raportów cyklicznych”,</li>
                <li>za pomocą przycisku „Nowy raport o dowolnym NIP” w Menu.</li>
              </ul>
              <p>
                Podobieństwa i różnice między pobieraniem Raportów KG z poziomu serwisu a pobieraniem ich z poziomu
                raportu cyklicznego są opisane w Samouczku w punkcie „9.5 Pobranie raportu o kliencie z raportu
                cyklicznego a pobranie z serwisu RiskRadar.”.
              </p>
              <p>
                W przypadku pobierania Raportu KG odnośnie niektórych podmiotów (np. podmiotu zagranicznego, oddziału
                przedsiębiorcy zagranicznego w Polsce czy gospodarstwa rolnego) w niektórych sekcjach raportu informacje
                mogą być niedostępne. Jest to normalna sytuacja. Wynika ona z tego, że w niektórych weryfikowanych
                źródłach sprawdzany podmiot może nie figurować. Analogiczna sytuacja wystąpi w przypadku pobierania
                Raportu KG dla ciągu cyfr, który co prawda spełnia kryteria walidacji, ale nie jest numerem NIP
                jakiegokolwiek podmiotu (np. 3213213218).
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak pobrać kompleksowy raport o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Raport KG można pobrać w następujących miejscach:</p>
              <ul>
                <li>
                  na liście klientów – w kolumnie „Raporty” za pomocą przycisku w formie ikony pobierania”:
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2a.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  w karcie klienta – za pomocą przycisku „Pobierz nowy raport” w sekcji „Raporty”
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2b.png')}
                      alt="Jak wyłączyć usługę monitorowania?"
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w raporcie cyklicznym w listach szczegółowych za pomocą przycisków „Pobierz” (szczegóły w Samouczku w
                  punkcie „9.1. Co zawierają raporty cykliczne?”
                </li>
                <li>
                  za pomocą przycisku „Nowy raport o dowolnym NIP” w Menu (szczegóły w Samouczku w punkcie „12.1. Do
                  czego służy i jak działa przycisk „Nowy raport o dowolnym NIP”?”
                </li>
              </ul>
              <p>
                Nie ma możliwości pobierania Raportów KG w ramach akcji grupowej, czyli dla wielu klientów jednocześnie.
              </p>
              <p>
                Generowanie Raportu KG trwa zwykle około 30 sekund. W czasie, kiedy Raport KG jest generowany widoczna
                jest ikona klepsydry i komunikat o trwającym procesie.
              </p>
              <p>Po wygenerowaniu się Raportu KG o kliencie w serwisie RiskRadar:</p>
              <ul>
                <li>
                  w prawym górnym rogu ekranu pojawi się zielone powiadomienie z linkiem prowadzącym do widoku „Historia
                  pobranych raportów”, gdzie można pobrać wygenerowany Raport KG:
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2c.png')}
                      alt={`"Powiadomienie z linkiem "SPRAWDŹ".`}
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li>
                  w widoku „Lista klientów” pojawi się samoczynnie (tj. bez konieczności odświeżania strony) zmieniona
                  ikona, która widoczna będzie do końca doby dla wszystkich użytkowników serwisu w danej firmie:
                  <div
                    className={`${styles.tutorialImageWrapper} ${styles.horizontallImgWithMarginBottom} ${styles.horizontallImgWithMarginTop}`}>
                    <img
                      src={require('../../../assets/images/tutorial/5-2d.png')}
                      alt="Zmieniona ikona na liście klientów."
                      className={styles.horizontallImg}
                    />
                  </div>
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w karcie klienta link do pobrania raportu wyświetli się samoczynnie (bez konieczności odświeżenia
                  strony).
                </li>
                <li className={styles.horizontallImgWithMarginBottom}>
                  w widoku „Historia pobranych raportów” statusy pobieranych Raportów KG zmienią się samoczynnie (bez
                  konieczności odświeżania strony).
                </li>
              </ul>
              <p>Podgląd pobranego wcześniej Raportu KG jest możliwy w następujących miejscach:</p>
              <ul>
                <li>
                  na liście klientów – można tu przejrzeć trzy ostatnie pobrane Raporty KG (nie starsze niż 7 dni) lub
                  skorzystać z opcji „Więcej”, która przekierowuje do karty klienta z pełną listą Raportów KG,
                </li>
                <li>
                  w karcie klienta – można tu przejrzeć wszystkie pobrane Raporty KG o danym kliencie (nie starsze niż 7
                  dni),
                </li>
                <li>
                  w zakładce „Historia pobranych raportów” – można tu przejrzeć wszystkie Raporty KG pobrane w RiskRadar
                  przez wszystkich użytkowników danej firmy (więcej w Samouczku w punkcie „11.1. Co zawiera zakładka
                  „Historia pobranych raportów”?”).
                </li>
              </ul>
              <p>
                Naliczenie usług następuje po kliknięciu przycisku służącego do pobierania Raportu KG i jego skutecznym
                wygenerowaniu. Naliczenie usług odbywa się w ramach przyznanego limitu (jeśli limit nie został
                wykorzystany) lub w ramach przekroczenia (jeśli limit został już wykorzystany). Więcej informacji na ten
                temat znajduje się w Samouczku w punkcie „6. Opłaty miesięczne i przekroczenia”.
              </p>
              <p>
                Z uwagi na to, że wygenerowanie aktualnego Raportu KG powoduje naliczanie usług, należy wykonywać to
                działanie rozważnie, po przemyśleniu, czy rzeczywiście taki raport ma zostać wygenerowany o danym
                kliencie.
              </p>
              <p>
                Informacje na temat licznika usług znajdują się w Samouczku w punkcie „6.4. Jak działa licznik usług?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak wyłączyć usługę raportu o kliencie?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Tej usługi nie wyłącza się. Raport KG można tylko pobrać, nie da się go wyłączyć tak jak usługi
                monitorowania. Nie ma możliwości cofnięcia ani anulowania operacji pobrania Raportu KG.{' '}
              </p>
              <p>
                Można natomiast wyłączyć uprawnienia danego użytkownika do pobierania Raportów KG i wtedy użytkownik ten
                nie będzie mógł pobierać Raportów KG o klientach. Działanie to może wykonać Administrator firmy w ramach
                ustawiania uprawnień danego użytkownika.
              </p>
              <p>Informacje dotyczące sposobu naliczania pobrań Raportów KG znajdują się w Samouczku w sekcjach:</p>
              <ul>
                <li>„6.1. Co składa się na usługi w ramach opłaty miesięcznej?” </li>
                <li>„6.4. Jak działa licznik usług?”</li>
              </ul>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Opłaty miesięczne i przekroczenia',
    subsections: [
      {
        name: 'Co składa się na usługi w ramach opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Korzystanie z RiskRadar możliwe jest po zawarciu i zaakceptowaniu:</p>
              <ul>
                <li>Indywidualnych Warunków Umowy o Świadczenie Pakietu Usług RiskRadar,</li>
                <li>Regulaminu Serwisu RiskRadar,</li>
                <li>Polityki Prywatności Serwisu RiskRadar,</li>
                <li>Ogólnych Warunków Świadczenia Usługi Monitorowania Kontrahenta,</li>
                <li>Ogólnych Warunków Świadczenia Usługi Raport KG.</li>
              </ul>
              <p>Na usługi w ramach opłaty miesięcznej składają się:</p>
              <ul>
                <li>
                  możliwość korzystania z usługi monitorowania dla określonej liczby klientów, w skład której wchodzą:
                  <ul>
                    <li>sprawdzanie kwoty zadłużenia w KRD BIG S.A.,</li>
                    <li>sprawdzanie zdarzeń w KRD BIG S.A.,</li>
                    <li>
                      sprawdzanie wyniku analizy wiarygodności płatniczej wykonywanej przez KRD BIG S.A.:
                      <ul className={styles.squareList}>
                        <li>w postaci kategorii wiarygodności płatniczej,</li>
                        <li>w postaci limitu kupieckiego (jeżeli usługa ta jest aktywna).</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  możliwość pobrania określonej liczby Raportów KG o klientach zgodnie z limitem wskazanym w
                  Indywidualnych Warunkach Umowy
                </li>
                <li>możliwość korzystania z serwisu RiskRadar (KG sp.j.).</li>
              </ul>
              <p>
                Wspomniane powyżej „określone liczby” zależą od limitów dostępnych dla danej firmy w ramach opłaty
                miesięcznej (określonych w Indywidualnych Warunkach Umowy). Jeśli przykładowo dana firma posiada limit
                obejmujący możliwość monitorowania 100 podmiotów oraz pobrania Raportów KG o 100 podmiotach, to w ramach
                tego można bez dodatkowych kosztów:
              </p>
              <ul>
                <li>monitorować jednocześnie do 100 dowolnych klientów w danym miesiącu,</li>
                <li>pobrać do 100 Raportów KG o dowolnych klientach w danym miesiącu.</li>
              </ul>
              <p>
                Usługi monitorowania oraz Raportów KG są od siebie niezależne. Oznacza to, że można monitorować pewną
                grupę klientów i pobrać Raporty KG dla zupełnie innej grupy klientów. Grupy te mogą, ale nie muszą się
                pokrywać. Przykładowo, dla danego klienta można:
              </p>
              <ul>
                <li>pobrać Raport KG i mieć uruchomione monitorowanie,</li>
                <li>pobrać Raport KG, ale mieć wyłączone monitorowanie,</li>
                <li>mieć uruchomione monitorowanie, ale nie pobierać Raportu KG,</li>
                <li>mieć wyłączone monitorowanie i nie pobierać Raportu KG.</li>
              </ul>
              <p>Po zakończeniu danego miesiąca i z chwilą rozpoczęcia nowego miesiąca: </p>
              <ul>
                <li>licznik monitorowanych firm nie resetuje się,</li>
                <li>licznik pobranych Raportów KG resetuje się do zera.</li>
              </ul>
              <p>
                Niewykorzystany limit Raportów KG w danym miesiącu nie przechodzi na kolejny miesiąc. Więcej informacji
                na ten temat znajduje się w Samouczku w punkcie „6.4 Jak działa licznik usług?”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jakie są opłaty za przekroczenie przyznanych limitów usług w ramach opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Po wykorzystaniu limitów w ramach opłaty miesięcznej naliczane są opłaty za ich przekroczenie. Opłaty te
                są naliczane osobno dla usługi monitorowania (składającej się ze sprawdzania kwoty zadłużenia w KRD BIG
                S.A., sprawdzania zdarzeń w KRD BIG S.A., sprawdzania kategorii wiarygodności płatniczej w KRD BIG S.A.)
                oraz osobno dla usługi Raportu KG (usługa świadczona przez KG sp.j.). Wysokość opłat jest określona w
                cenniku w umowie.
              </p>
              <p>
                Przykład 1. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie pobierania
                Raportów KG. Jeśli w danym miesiącu pobrano 110 aktualnych Raportów KG o klientach, to przekroczenie
                wynosi 10 i za owe 10 naliczana jest opłata jednostkowa zgodnie z obowiązującym cennikiem.
              </p>
              <p>
                Przykład 2. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania. Jeśli w danym miesiącu maksymalna liczba uruchomionych jednocześnie usług monitorowania
                klientów wynosi 110, to przekroczenie wynosi 10 i za owe 10 naliczana jest opłata jednostkowa zgodnie z
                obowiązującym cennikiem.
              </p>
              <p>
                Przykład 3. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nie nastąpiło przekroczenie limitu w ramach opłaty miesięcznej, ponieważ maksymalna
                liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 100.
              </p>
              <p>
                Przykład 4. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca o godz. 23:30 zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>16-tego dnia miesiąca o godz. 00:30 zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nie nastąpiło przekroczenie limitu w ramach opłaty miesięcznej, ponieważ maksymalna
                liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 100.
              </p>
              <p>
                Przykład 5. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>15-tego dnia miesiąca zostało włączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca zostało wyłączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nastąpiło przekroczenie limitu w ramach opłaty miesięcznej o 20, ponieważ
                maksymalna liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 120.
              </p>
              <p>
                Przykład 6. Firma posiada limit w ramach opłaty miesięcznej na 100 podmiotów w zakresie usługi
                monitorowania i w danym miesiącu:
              </p>
              <ul>
                <li>10-tego dnia miesiąca zostało włączone monitorowanie dla 100 klientów,</li>
                <li>20-tego dnia miesiąca o godz. 11:00 zostało wyłączone monitorowanie dla 20 klientów,</li>
                <li>20-tego dnia miesiąca o godz. 14:00 zostało włączone monitorowanie dla 20 klientów,</li>
              </ul>
              <p>
                to w takiej sytuacji nastąpiło przekroczenie limitu w ramach opłaty miesięcznej o 20, ponieważ
                maksymalna liczba jednocześnie monitorowanych klientów wyniosła w tym miesiącu 120 (opłata naliczana
                jest za całą dobę niezależnie od tego, czy monitorowanie w danej dobie trwało 3 minuty czy 24 godziny).
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dokonać zmiany limitów usług i opłaty miesięcznej?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W celu dokonania zmiany limitów usług i opłaty miesięcznej na niższe lub wyższe należy skontaktować się
                ze swoim opiekunem po stronie KG. Limit i opłatę miesięczną warto zmienić na wyższe, gdy koszt usług
                wykorzystanych poza opłatą miesięczną zaczyna przekraczać koszty opłaty miesięcznej wyższego poziomu.
                Limit i opłatę miesięczną warto zmienić na niższe, gdy w ramach danej firmy obecny limit w ramach opłaty
                miesięcznej jest w znacznym stopniu niewykorzystywany.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak działa licznik usług?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>W RiskRadar, w pasku górnym po prawej stronie, dostępne są dwa liczniki usług:</p>
              <ul>
                <li>
                  licznik aktualnie monitorowanych klientów;
                  <ul>
                    <li>
                      pokazuje, dla ilu klientów jest aktualnie włączone monitorowanie (w ramach całej firmy, tj. przez
                      wszystkich użytkowników),
                    </li>
                    <li>licznik ten nie zeruje się na koniec każdego miesiąca kalendarzowego,</li>
                    <li>
                      stan licznika ulega zwiększeniu o 1 w momencie włączenia monitorowania klienta (włączanie
                      monitorowania jest opisane w Samouczku w punkcie „3.2. Jak włączyć usługę monitorowania?”),
                    </li>
                    <li>
                      stan licznika ulega zmniejszeniu o 1 w momencie wyłączenia monitorowania klienta (wyłączanie
                      monitorowania jest opisane w Samouczku w punkcie „3.3. Jak wyłączyć usługę monitorowania?”),
                    </li>
                  </ul>
                </li>
                <li>
                  licznik wszystkich Raportów KG o kliencie pobranych w bieżącym miesiącu;
                  <ul>
                    <li>
                      pokazuje, ile Raportów KG zostało pobranych w danym miesiącu (w ramach całej firmy, tj. przez
                      wszystkich użytkowników):
                      <ul>
                        <li>uwzględniane są pobrania Raportów KG o klientach z poziomu serwisu RiskRadar,</li>
                        <li>uwzględniane są pobrania Raportów KG o klientach z poziomu raportu cyklicznego,</li>
                      </ul>
                    </li>
                    <li>licznik ten zeruje się na koniec każdego miesiąca kalendarzowego,</li>
                    <li>stan licznika ulega zwiększeniu o 1 w momencie pobrania Raportu KG,</li>
                    <li>
                      stan licznika nie ulega zmniejszeniu w żadnej sytuacji (za wyjątkiem zerowania na koniec miesiąca
                      kalendarzowego).
                    </li>
                  </ul>
                </li>
              </ul>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/6-4.png')}
                  alt="Jak działa licznik usług?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>RiskRadar nie pokazuje:</p>
              <ul>
                <li>informacji o posiadanym limicie w ramach opłaty miesięcznej (np. 100 podmiotów),</li>
                <li>informacji o liczbie usług pozostałych do wykorzystania w ramach opłaty miesięcznej.</li>
              </ul>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zarządzanie klientami',
    subsections: [
      {
        name: 'Jak dodawać pojedynczych klientów?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Na ekranie z listą klientów należy kliknąć przycisk „Dodaj klienta”. Otworzy się nowa karta, w której
                można dodać nowego klienta korzystając z podpowiedzi w wyszukiwarce (w polu „Wyszukaj klienta po NIP lub
                nazwie” należy wpisać minimum 3 znaki) lub wpisując dane klienta ręcznie w poszczególnych polach
                formularza. Formularz zawiera pola do wpisania numeru NIP (pole obowiązkowe), nazwy klienta (pole
                obowiązkowe), dowolnego opisu (2 pola opcjonalne), adresu (pola opcjonalne). Nazwa klienta nie musi być
                pełną nazwą rejestrową (np. zgodnie z KRS), może być to nazwa uproszczona. Operację należy potwierdzić
                przyciskiem „Zapisz”. Od tego momentu nowy klient będzie widoczny w RiskRadar. Przycisk „Wyczyść”
                powoduje usunięcie danych wprowadzonych do formularza.
              </p>
              <p>
                Wprowadzając NIP należy pamiętać, że jeśli firma jest z Polski, to numer NIP można wpisać bez prefiksu
                „PL” lub z prefiksem „PL” (obydwie formy są dopuszczalne). Jeśli firma jest z UE, to należy wpisać numer
                NIP (lub inny numer identyfikacyjny na potrzeby podatkowe w przypadku podmiotu zagranicznego z UE)
                zawsze z prefiksem danego kraju. Nie można dodawać klientów spoza UE.
              </p>
              <p>
                Dodanie w ten sposób klienta nie powoduje włączenia ani naliczenia żadnych usług w RiskRadar. Chcąc
                włączyć usługi dotyczące danego klienta warto zapoznać się z punktem „3.2. Jak włączyć usługę
                monitorowania?” oraz punktem „5.2. Jak włączyć usługę raportu o kliencie?” w Samouczku.
              </p>
              <p>Wizualizacja poglądowa ekranu do dodawania nowego klienta:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-1.png')}
                  alt="Jak dodawać pojedynczych klientów?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak usunąć klienta z RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Operację tę można wykonać na karcie klienta za pomocą przycisku „Usuń klienta”. Po kliknięciu tego
                przycisku pojawi się dodatkowy komunikat potwierdzający tę operację.
              </p>
              <p>
                Możliwość usunięcia klienta dotyczy każdego klienta, niezależnie od tego, czy był monitorowany, czy
                pobrano o nim Raporty KG, itp. Działanie to spowoduje, że usunięty klient nie będzie już widoczny w
                RiskRadar, a wszystkie jego dane zostaną usunięte (np. historia zdarzeń w KRD BIG S.A., pobrane Raporty
                KG). W przyszłości można dodać tego klienta ponownie.
              </p>
              <p>
                Usunięcie klienta powoduje automatyczne wyłączenie usługi monitorowania tego klienta (jeśli było
                włączone).
              </p>
              <p>Wizualizacja poglądowa ekranu z opcją usuwania klienta: </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-2.png')}
                  alt="Jak usunąć klienta z RiskRadar?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Do czego służy i jak działa import za pomocą pliku CSV?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Import za pomocą pliku CSV służy do wykonywania akcji masowych (np. dodawania do RiskRadar większej
                liczby klientów) w sprawny sposób. Wystarczy, że przygotowany zostanie odpowiedni plik z danymi tych
                klientów. Wymagania dla takiego pliku są opisane w Samouczku w punkcie „7.4. Jakie kryteria musi
                spełniać plik CSV, aby import się powiódł?”.
              </p>
              <p>
                W RiskRadar dostępna jest lista przeprowadzonych importów plików CSV. Dla każdego importu lista
                prezentuje informacje o nazwie pliku (z możliwością jego pobrania), datę importu (na moment jego
                zakończenia), liczbę rekordów zaimportowanych (tylko dla importów zakończonych), status (oczekujący, w
                toku, zakończony, błędny). Lista importów zawiera pliki importowane w ciągu ostatnich 12 miesięcy, pliki
                importowane wcześniej nie są widoczne na liście.
              </p>
              <p>
                Podgląd listy importów jest dostępny dla wszystkich użytkowników w ramach danej firmy – każdy użytkownik
                może np. sprawdzić, kiedy miał miejsce ostatni import danych. Operację importu CSV może natomiast
                wykonywać wyłącznie użytkownik posiadający do tego wymagane uprawnienia. Uprawnienia te nadaje
                administrator RiskRadar w danej firmie.
              </p>
              <p>W RiskRadar stosowana jest następująca logika przy importowaniu danych z pliku CSV:</p>
              <ul>
                <li>
                  jeśli dany klient jest w pliku CSV, a nie było go w RiskRadar, to zostanie on dodany do RiskRadar,
                </li>
                <li>
                  jeśli dany klient jest w pliku CSV i był w RiskRadar dodany przez import CSV, to jego dane zostaną
                  nadpisane,
                </li>
                <li>
                  jeśli danego klienta nie ma w pliku CSV, a był w RiskRadar dodany przez import CSV, to jego dane
                  zostaną oznaczone jako „stare”,
                </li>
                <li>
                  jeśli dany klient jest w pliku CSV i był w RiskRadar dodany ręcznie, to zostanie on dodany do
                  RiskRadar (w efekcie w RiskRadar będzie 2 klientów o tym samym numerze NIP).
                </li>
              </ul>
              <p>
                W czasie importu pliku CSV (tj. zwykle około 10 minut) operacje dotyczące wszystkich importowanych
                klientów są zablokowane, tj.:
              </p>
              <ul>
                <li>na „Liście klientów” przyciski "Włącz monitorowanie" są wyszarzone i nieklikalne,</li>
                <li>na „Liście klientów” przyciski akcji grupowych są wyszarzone i nieklikalne,</li>
                <li>
                  na karcie klienta przyciski „Modyfikuj”, „Usuń klienta”, „Włącz monitorowanie”, „Wyłącz monitorowanie”
                  są wyszarzone i nieklikalne.
                </li>
              </ul>
              <p>
                Na wyszarzonych i nieklikalnych przyciskach, w trakcie trwania tej blokady, wyświetla się dymek o treści
                „Obecnie nie można wykonać tej operacji, ponieważ jeden z użytkowników w Twojej firmie importuje dane
                dotyczące tego klienta. Spróbuj ponownie za około 10 minut.”
              </p>
              <p>
                Podczas operacji importu danych z pliku CSV usuwane są wszystkie znaki cudzysłowu górnego i dolnego z
                pola „Nazwa firmy” niezależnie od tego, w którym miejscu nazwy firmy występują. Działanie to jest
                wykonywane w celu ułatwienia późniejszej pracy z danymi w RiskRadar, w szczególności sortowania po
                nazwie klienta.
              </p>
              <p>
                Po zakończeniu importu w serwisie RiskRadar w prawym górnym rogu ekranu pojawi się zielone powiadomienie
                z linkiem prowadzącym do zakładki „Import danych – CSV”.
              </p>
              <p>Wizualizacja poglądowa zakładki „Lista importów”:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/7-3.png')}
                  alt="Do czego służy i jak działa import za pomocą pliku CSV?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jakie kryteria musi spełniać plik CSV, aby import się powiódł?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>RiskRadar stosuje następujące kryteria weryfikacji poprawności pliku CSV:</p>
              <ul>
                <li>kryteria poprawności dla pliku:</li>
                <ul>
                  <li>plik jest w formacie CSV,</li>
                  <li>plik ma kodowanie UTF8 BOM,</li>
                  <li>plik zawiera pierwszy wiersz z nazwami kolumn (dokładnie według szablonu),</li>
                  <li>kolumny mają odpowiednie nazwy (zgodnie z szablonem),</li>
                  <li>kolumny mają odpowiednią kolejność (zgodnie z szablonem),</li>
                  <li>separatory komórek są średnikami,</li>
                  <li>jest uzupełniony przynajmniej jeden rekord z danymi,</li>
                  <li>nie ma rekordów zawierających ten sam NIP,</li>
                </ul>
                <li>kryteria poprawności dla poszczególnych wierszy w pliku:</li>
                <ul>
                  <li>są uzupełnione wszystkie dane obowiązkowe, tj.: NIP i Nazwa firmy,</li>
                  <li>numery NIP są poprawne (10 cyfr lub 10 cyfr i prefiks kraju, poprawna suma kontrolna),</li>
                  <li>wartości liczbowe w polach Sprzedaż i Należności są liczbami bez spacji,</li>
                  <li>pozycje dziesiętne w polach Sprzedaż i Należności są oddzielone przecinkiem,</li>
                  <li>maksymalna liczba znaków w polach Sprzedaż i Należności nie przekracza 16,</li>
                  <li>maksymalna liczba znaków w polu „Nazwa firmy” nie przekracza 100 znaków,</li>
                  <li>maksymalna liczba znaków w polu „Opis 1” i „Opis 2” nie przekracza 30 znaków,</li>
                  <li>typ operacji to zawiera dokładnie jedną dozwoloną wartość (tj. puste, 1, 2, 3, 4).</li>
                </ul>
              </ul>
              <p>Zakres danych w pliku CSV:</p>
              <ul>
                <li>Typ operacji (pole opcjonalne),</li>
                <li>NIP (pole obligatoryjne),</li>
                <li>Nazwa firmy (pole obligatoryjne),</li>
                <li>Wartość sprzedaży brutto za ostatnie 12 miesięcy wyrażona w PLN (pole opcjonalne),</li>
                <li>Wartość należności brutto na dzień importu wyrażona w PLN (pole opcjonalne),</li>
                <li>Opis 1 (pole opcjonalne),</li>
                <li>Opis 2 (pole opcjonalne).</li>
              </ul>
              <p>Wskazówki dotyczące numerów NIP:</p>
              <ul>
                <li>jeśli podmiot jest z Polski to należy wpisać 10 cyfr bez prefiksu „PL” lub z prefiksem „PL”,</li>
                <li>
                  jeśli podmiot jest z UE to należy wpisać prefiks kraju i numer identyfikacyjny podmiotu (na potrzeby
                  podatkowe) zagranicznego w UE,
                </li>
                <li>
                  jeśli podmiot jest spoza UE to nie należy wpisywać jego numeru NIP (takie podmioty nie są
                  obsługiwane).
                </li>
              </ul>
              <p>
                Wzór pliku CSV służącego do dodawania klientów znajduje się w zakładce „Import danych – CSV” w Menu.
              </p>
              <p>
                Dodatkowe wskazówki dotyczące przygotowania poprawnego pliku CSV znajdują się w Samouczku w punkcie
                „7.7. Wskazówki dotyczące przygotowania poprawnego pliku CSV do importu.”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dokonać importu danych o klientach za pomocą pliku CSV?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Operację importu CSV może wykonywać wyłącznie użytkownik posiadający do tego wymagane uprawnienie.
                Uprawnienia te nadaje administrator RiskRadar w danej firmie. Posiadając takie uprawnienia, można
                dokonać importu pliku CSV.
              </p>
              <p>
                W celu wykonania importu należy wejść przez Menu do zakładki „Import danych – CSV” i pobrać na swoje
                urządzenie szablon pliku CSV. Należy uzupełnić dane w pliku CSV zgodnie z wytycznymi, o których mowa w
                Samouczku w punkcie „7.4. Jakie kryteria musi spełniać plik CSV, aby import się powiódł?”. Gotowy plik
                należy wgrać do RiskRadar. Następnie RiskRadar zweryfikuje poprawność pliku i danych w nim zawartych.
                Weryfikacja ta nie dotyczy jednak tego, czy dane są poprawne merytorycznie (np. czy zamiast przychodów
                12345,67 nie powinno być 123456,70).
              </p>
              <p>
                W przypadku braku jakiegokolwiek błędu danych przy imporcie pliku CSV RiskRadar zaimportuje wszystkie
                dane z tego pliku i wyświetli informację o sukcesie importu wraz z liczbą rekordów poprawnie
                zaimportowanych. Dane z pliku CSV zostaną dodane do RiskRadar.
              </p>

              <p>W przypadku wystąpienia jednego lub więcej błędów danych przy imporcie pliku CSV RiskRadar:</p>
              <ul>
                <li>zaimportuje wszystkie poprawne rekordy z tego pliku (poprawne rekordy zostaną dodane),</li>
                <li>
                  wyświetli informację o sukcesie importu (status „Zakończony”) z liczbą rekordów zaimportowanych,
                </li>
                <li>wyświetli przycisk „Pobierz plik” pod którym znajduje się załadowany plik CSV,</li>
                <li>
                  wyświetli pomarańczowy trójkąt ostrzegawczy oraz przycisk „Pokaż błędy”, pod którym znajduje się wykaz
                  błędów dla rekordów, które nie zostały zaimportowane.
                </li>
              </ul>
              <p>
                Jeśli natomiast błędny jest sam plik (np. nie jest w formacie csv lub nie ma kodowania UTF8 BOM), to
                import jest przerywany, zwracany jest powód niewykonania importu i żadne dane nie są wtedy dodawane do
                RiskRadar.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Wykonywanie działań masowych za pomocą kodów operacji przy imporcie pliku CSV.',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                RiskRadar daje możliwość wykonania akcji masowych za pomocą kodów operacji wpisywanych w pliku CSV.
                Służy do tego kolumna „Typ operacji” w szablonie pliku CSV. Jest to pole opcjonalne. Dla danego NIPu
                może być wprowadzona tylko jedna wartość, tj. można zlecić tylko jeden rodzaj operacji dla danego NIPu w
                ramach danego importu pliku CSV.
              </p>
              <p>
                UWAGA: Użytkownik, który chce włączyć lub wyłączyć monitorowanie za pomocą kodów w imporcie CSV musi
                mieć nadane uprawnienie „Włączanie i wyłączanie monitorowania klienta”. W przeciwnym razie polecenia
                włączenia czy wyłączenia monitorowania nie zostaną wykonane.
              </p>
              <p>
                Użytkownik nie otrzymuje informacji zwrotnej o wykonaniu lub niewykonaniu operacji zleconych za pomocą
                kodów przy imporcie pliku CSV.
              </p>
              <p>
                Wpisanie kodu 1 (dodanie klienta i włączenie monitorowania) powoduje:
                <ul>
                  <li>dodanie klienta do RiskRadar (jeśli nie był wcześniej dodany przez CSV),</li>
                  <li>rozpoczęcie włączania monitorowania na danym NIP (jeśli było wyłączone),</li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 2 (dodanie klienta bez włączania monitorowania) lub pozostawienie pustego pola powoduje:
                <ul>
                  <li>dodanie klienta do RiskRadar (jeśli nie był wcześniej dodany przez CSV), </li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 3 (wyłączenie monitorowania) powoduje:
                <ul>
                  <li>
                    wyłączenie monitorowania na danym NIP (jeśli było włączone),
                    <ul>
                      <li>nastąpi to niezależnie od tego, czy klient był dodany przez CSV czy ręcznie,</li>
                    </ul>
                  </li>
                  <li>nadpisanie danych klienta (jeśli istnieje w RiskRadar i był dodany przez CSV).</li>
                </ul>
              </p>
              <p>
                Wpisanie kodu 4 (usunięcie klienta i wyłączenie monitorowania) powoduje:
                <ul>
                  <li>
                    usunięcie wszystkich kont klienta o danym NIP,
                    <ul>
                      <li>dotyczy to zarówno klientów dodanych ręcznie jak i przez CSV,</li>
                      <li>nastąpi to niezależnie od tego, jaką nazwę klienta wpisano w pliku CSV,</li>
                    </ul>
                  </li>
                  <li>
                    wyłączenie monitorowania na danym NIP (jeśli było włączone),
                    <ul>
                      <li>nastąpi to niezależnie od tego, czy klient był dodany przez CSV czy ręcznie.</li>
                    </ul>
                  </li>
                </ul>
              </p>
            </div>
          );
        }
      },
      {
        name: 'Wskazówki dotyczące przygotowania poprawnego pliku CSV do importu.',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p className={styles.textUnderline}>Jak sprawdzić, czy numer NIP jest poprawny?</p>
              <p>Można w tym celu użyć wyszukiwarki REGON:</p>
              <p>
                <a
                  href="https://wyszukiwarkaregon.stat.gov.pl/appBIR/index.aspx"
                  rel="noopener noreferrer"
                  target="_blank">
                  https://wyszukiwarkaregon.stat.gov.pl/appBIR/index.aspx
                </a>
              </p>
              <p className={styles.textUnderline}>Jak można edytować plik CSV?</p>
              <p>Do edycji pliku CSV można wykorzystać między innymi:</p>
              <ul>
                <li>arkusz (np. Excel) – pokazuje dane w układzie kolumnowym,</li>
                <li>notatnik (np. Notepad) – pokazuje dane w układzie tekstowym, bez tabulacji.</li>
              </ul>
              <p className={styles.textUnderline}>Jak sprawdzić, czy plik ma kodowanie UTF-8?</p>
              <ol>
                <li>Otwórz plik CSV w MS Excel.</li>
                <li>Kliknij „Plik” i następnie „Zapisz jako”.</li>
                <li>W polu „Zapisz jako typ” winna być pozycja „Plik CSV UTF-8 (rozdzielany przecinkami) (*csv).”</li>
              </ol>
              <p className={styles.textUnderline}>Jak sprawdzić, czy plik ma kodowanie UTF-8 BOM?</p>
              <ol>
                <li>Otwórz plik CSV w Notatnik.</li>
                <li>Kliknij „Plik” i następnie „Zapisz jako”.</li>
                <li>W polu „Kodowanie” winna być pozycja „UTF-8 z BOM”</li>
              </ol>
              <p className={styles.textUnderline}>
                Jak sprawdzić i skrócić liczbę znaków w komórkach z kolumny „Nazwa firmy”?
              </p>
              <ol>
                <li>Otwórz plik CSV w MS Excel.</li>
                <li>Użyj funkcji „DŁ” na komórkach z kolumny „Nazwa firmy”.</li>
                <li>Sprawdź, czy są komórki zawierające więcej niż 100 znaków.</li>
                <li>Skróć nazwy firm w komórkach (edytując zawartość komórki) mających więcej niż 100 znaków.</li>
                <li>Zapisz plik.</li>
              </ol>
              <p className={styles.textUnderline}>Jak zidentyfikować rekordy z takim samym numerem NIP?</p>
              <ol>
                <li>Otwórz plik CSV w MS Excel.</li>
                <li>Zaznacz całą kolumnę „NIP”.</li>
                <li>
                  Narzędzia główne &gt; Formatowanie warunkowe &gt; Reguły wyróżniania komórek &gt; Duplikujące się
                  wartości &gt; OK
                </li>
                <li>Przejrzyj listę w poszukiwaniu wierszy, które zostały wyróżnione kolorem. </li>
                <li>Jeśli plik ma bardzo dużo wierszy, użyj opcji Dane &gt; Filtruj.</li>
                <li>
                  W kolumnie NIP użyj opcji „Filtruj według koloru” i następnie „Filtruj według kolorów komórek”:{' '}
                </li>
                <li>Przejrzyj listę wierszy, które są zduplikowane. </li>
                <li>Usuń duplikaty. </li>
                <li>Zapisz plik.</li>
              </ol>
              <p className={styles.textUnderline}>Jak usunąć rekordy z takim samym numerem NIP?</p>
              <ol>
                <li>Otwórz plik CSV w MS Excel.</li>
                <li>W menu przejdź do zakładki „Dane” i kliknij „Usuń duplikaty”.</li>
                <li>Zaznacz tylko kolumnę „NIP” i naciśnij „OK”.</li>
                <li>Wyświetla się komunikat z liczbą usuniętych zduplikowanych wartości. </li>
                <li>Zapisz plik. </li>
              </ol>
              <p className={styles.textUnderline}>Jak sprawdzić i usunąć spacje z wartości liczbowych?</p>
              <ol>
                <li>Otwórz plik CSV w MS Excel.</li>
                <li>W menu przejdź do zakładki „Dane” i kliknij „Filtruj”.</li>
                <li>W kolumnie C (a potem D, E, F) w komórce nagłówka kliknij przycisk z trójkątem.</li>
                <li>W polu „Filtry liczb” wpisz znak spacji i naciśnij „OK”.</li>
                <li>Wyświetlą się rekordy, które w danej kolumnie mają znak spacji. </li>
                <li>Usuń znaki spacji ze wszystkich wyświetlonych rekordów. </li>
                <li>Zapisz plik. </li>
              </ol>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Zarządzanie użytkownikami',
    subsections: [
      {
        name: 'Jakie są rodzaje użytkowników i jakie mają uprawnienia?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>W RiskRadar mogą występować następujące rodzaje użytkowników w ramach danej firmy:</p>
              <ul>
                <li>Administrator firmy,</li>
                <li>Pracownik firmy, </li>
                <li>Użytkownik tylko raportów o NIP, </li>
                <li>Użytkownik bez dostępu do serwisu, </li>
                <li>Konto techniczne.</li>
              </ul>
              <p>Użytkownik w roli „Administratora firmy”:</p>
              <ul>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>przeglądanie danych na liście klientów i karcie klienta,</li>
                    <li>dodanie (ręczne) / aktualizacja / usunięcie klienta,</li>
                    <li>ustawianie / resetowanie własnego hasła,</li>
                    <li>wyświetlenie listy użytkowników firmy,</li>
                    <li>dodanie, aktualizacja, usunięcie kont użytkowników firmy,</li>
                    <li>zmiana statusu kont użytkowników (aktywne lub nieaktywne),</li>
                    <li>dodawanie i usuwanie uprawnień użytkowników firmy,</li>
                    <li>wyświetlanie listy raportów cyklicznych,</li>
                    <li>dodawanie, modyfikowanie, usuwanie raportów cyklicznych w „Kreatorze raportów cyklicznych”,</li>
                    <li>dodawanie, usuwanie użytkowników otrzymujących raporty cykliczne poprzez e-mail,</li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>włączanie i wyłączanie monitorowania klienta,</li>
                    <li>
                      możliwość pobierania aktualnego Raportu KG o kliencie,
                      <ul>
                        <li>z poziomu serwisu RiskRadar,</li>
                        <li>z poziomu raportu cyklicznego,</li>
                      </ul>
                    </li>
                    <li>możliwość zarządzania importem danych z CSV.</li>
                  </ul>
                </li>
              </ul>
              <p>Użytkownik w roli „Pracownika firmy”:</p>
              <ul>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>przeglądanie danych na liście klientów i karcie klienta,</li>
                    <li>dodanie (ręczne) / aktualizacja / usunięcie klienta,</li>
                    <li>ustawianie / resetowanie własnego hasła.</li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>włączanie i wyłączanie monitorowania klienta,</li>
                    <li>
                      możliwość pobierania aktualnego Raportu KG o kliencie,
                      <ul>
                        <li>z poziomu serwisu RiskRadar,</li>
                        <li>z poziomu raportu cyklicznego,</li>
                      </ul>
                    </li>
                    <li>możliwość zarządzania importem danych z CSV.</li>
                  </ul>
                </li>
              </ul>
              <p>Użytkownik w roli „Użytkownik tylko raportów o NIP”:</p>
              <ul>
                <li>posiada następujące uprawnienia podstawowe:</li>
                <ul>
                  <li>ustawianie / resetowanie własnego hasła;</li>
                </ul>
                <li>posiada nadane uprawnienia dodatkowe:</li>
                <ul>
                  <li>możliwość pobierania aktualnego Raportu KG o kliencie (zawsze włączone);</li>
                </ul>
                <li>po zalogowaniu się do serwisu widzi wyłącznie następujące zakładki:</li>
                <ul>
                  <li>"Historia pobranych raportów",</li>
                  <li>"Nowy raport o dowolnym NIP",</li>
                  <li>"Samouczek";</li>
                </ul>
                <li>
                  na belce górnej serwisu RR nie widzi "Licznika wszystkich pobranych raportów" i "Licznika
                  monitorowanych NIPów",
                </li>
                <li>
                  nie może być odbiorcą raportów cyklicznych (nie można go wybrać na liście odbiorców w definicji
                  raportu cyklicznego).
                </li>
              </ul>
              <p>„Użytkownik bez dostępu do serwisu”:</p>
              <ul>
                <li>nie otrzymuje e-mail z linkiem do ustawienia hasła logowania do RiskRadar,</li>
                <li>nie ma dostępu do serwisu RiskRadar,</li>
                <li>
                  posiada następujące uprawnienia podstawowe:
                  <ul>
                    <li>otrzymywanie raportów cyklicznych poprzez e-mail (o ile został dodany do list odbiorców),</li>
                  </ul>
                </li>
                <li>
                  może mieć nadane uprawnienia dodatkowe:
                  <ul>
                    <li>
                      możliwość pobierania aktualnego Raportu KG o kliencie:
                      <ul>
                        <li>z poziomu raportu cyklicznego poprzez e-mail (o ile jest na liście odbiorców).</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>Konto techniczne:</p>
              <ul>
                <li>nie ma dostępu do serwisu RiskRadar.</li>
              </ul>
              <p>
                Informacje o klientach w ramach danej firmy są dostępne dla wszystkich użytkowników danej firmy, którzy
                posiadają takie uprawnienie (tj. mają role Administrator lub Pracownik). Oznacza to, że każdy użytkownik
                widzi wszystkich klientów dodanych do RiskRadar w ramach danej firmy a nie tylko tych, których dodał we
                własnym zakresie. Podobna zasada dotyczy włączania usług – tzn. dowolny użytkownik danej firmy
                posiadający odpowiednie uprawnienia ma możliwość włączania np. monitorowania dla każdego klienta danej
                firmy. Przykład: jeśli 10 użytkowników dodało po 10 klientów, to w efekcie każdy użytkownik ma dostęp do
                informacji o wszystkich klientach (tj. 100) i może włączyć dla nich np. monitorowanie.{' '}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak dodać nowego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Operację dodania nowego konta użytkownika może wykonać Administrator firmy (może on także utworzyć konto
                dla kolejnego Administratora lub Administratorów firmy). W tym celu należy wejść do zakładki
                „Użytkownicy i uprawnienia” w Menu i użyć przycisku „Dodaj użytkownika”.
              </p>
              <p>Krok 1. Uzupełnij wymagane dane (imię i nazwisko, e-mail, telefon).</p>
              <p>
                Krok 2. Wybierz jedną z ról dla użytkownika:
                <ul>
                  <li>Administrator firmy (może zarządzać użytkownikami oraz konfigurować raporty cykliczne),</li>
                  <li>Pracownik firmy (może się zalogować do serwisu i z niego korzystać),</li>
                  <li>
                    Użytkownik tylko raportów o NIP (może korzystać tylko z funkcjonalności dot. raportów o klientach),
                  </li>
                  <li>
                    Użytkownik bez dostępu do serwisu (nie może się zalogować do serwisu, ale może otrzymywać raporty
                    cykliczne poprzez e-mail),
                  </li>
                  <li>Konto techniczne.</li>
                </ul>
              </p>
              <p>
                Krok 3. Określ dodatkowe uprawnienia dla użytkownika:
                <ul>
                  <li>włączanie i wyłączanie monitorowania klienta,</li>
                  <li>możliwość pobierania aktualnego Raportu KG o kliencie,</li>
                  <li>możliwość zarządzania importem danych z CSV.</li>
                </ul>
              </p>

              <p>Operację należy potwierdź klikając przycisk „Zapisz”.</p>
              <p>
                RiskRadar stworzy nowe konto użytkownika i wyśle do niego e-mail z instrukcją ustawienia hasła (o ile
                dany użytkownik ma uprawnienia dostępu do serwisu RiskRadar).{' '}
              </p>
              <p>
                Podczas tworzenia nowego konta użytkownika sprawdzana jest unikalność podanego adresu e-mail w serwisie
                RiskRadar. Oznacza to, że nie można założyć więcej niż jednego konta użytkownika dla danego adresu
                e-mail.
              </p>
              <p>Wizualizacja poglądowa ekranu do dodawania nowego użytkownika:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/8-2.png')}
                  alt="Jak dodać nowego użytkownika?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak usunąć istniejącego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Administrator firmy może wykonać operację usunięcia istniejącego konta użytkownika (w tym innego
                Administratora firmy). W tym celu należy wejść do zakładki „Użytkownicy i uprawnienia” w Menu. Można
                usunąć konto użytkownika na dwa sposoby:
              </p>
              <ul>
                <li>z poziomu listy użytkowników, klikając w „Akcje”, a następnie przycisk „Usuń”,</li>
                <li>z poziomu karty użytkownika, klikając w przycisk „Usuń”.</li>
              </ul>
              <p>
                Wykonanie operacji usunięcia konta użytkownika spowoduje automatyczne wysłanie do usuniętego użytkownika
                powiadomienia e-mail o tym fakcie.
              </p>
              <p>Wizualizacja poglądowa ekranu z opcją usuwania użytkownika:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/8-3.png')}
                  alt="Jak usunąć istniejącego użytkownika?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak nadać hasło nowemu użytkownikowi?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Użytkownik ustawia swoje hasło samodzielnie, bez udziału Administratora. Administrator firmy nie nadaje
                hasła nowemu użytkownikowi RiskRadar. Po założeniu nowego konta użytkownika, otrzymuje on e-mail z
                instrukcją dotyczącą samodzielnego ustawienia hasła.
              </p>
              <p>
                Pierwszy użytkownik RiskRadar w danej firmie ma nadany domyślnie status Administratora i w dniu
                uruchomienia RiskRadar otrzymuje e-mail z instrukcją dotyczącą samodzielnego ustawienia hasła.
                Administrator może nadawać następnie prawa dostępu dla innych użytkowników, co jest opisane w Samouczku
                w punkcie „8.2. Jak dodać nowego użytkownika?”.
              </p>
              <p>
                Link do ustawienia hasła jest ważny przez 48 godzin. Jeśli ten czas już minął, można ustawić własne
                hasło korzystając z opcji “Nie pamiętam hasła” na stronie logowania{' '}
                <a href="https://panel.riskradar.pl/" rel="noopener noreferrer" target="_blank">
                  https://panel.riskradar.pl/
                </a>
                .
              </p>
              <p>
                W przypadku wygaśnięcia linku do ustawienia hasła zawartego w e-mailu z instrukcją samodzielnego
                ustawienia hasła, użytkownik otrzyma komunikat „Uprzejmie informujemy, że link do zmiany hasła wygasł.
                Chcąc zmienić swoje hasło, należy wejść na stronę logowania i wygenerować ponownie link do ustawienia
                hasła korzystając z opcji „Nie pamiętam hasła”. Jest to jedyna metoda ustawienia hasła w RiskRadar.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak zresetować hasło istniejącego użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Administrator firmy nie zmienia hasła użytkownika RiskRadar. Użytkownik zmienia swoje hasło
                samodzielnie, bez udziału Administratora.
              </p>
              <p>
                Jeśli użytkownik chce zmienić swoje hasło (np. z powodu jego zapomnienia) to wykonuje kolejno
                następujące czynności:
              </p>
              <ul>
                <li>wchodzi na ekran logowania,</li>
                <li>klika w link „Nie pamiętam hasła”,</li>
                <li>otrzymuje e-mail z linkiem do ustawienia nowego hasła,</li>
                <li>klika w link, który otwiera ekran służący do zmiany hasła,</li>
                <li>wprowadza nowe hasło i potwierdza to przyciskiem „Zapisz”.</li>
              </ul>
            </div>
          );
        }
      },
      {
        name: 'Jak zmodyfikować rolę lub uprawnienia użytkownika?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Będąc Administratorem firmy można zmienić zarówno rolę jak i uprawnienia danego użytkownika. W tym celu
                należy wejść do zakładki „Użytkownicy i uprawnienia” w Menu, następnie kliknąć w wybranego użytkownika.
                Otworzy się nowa karta przeglądarki z danymi użytkownika. Należy użyć przycisku „Modyfikuj” aby wejść w
                tryb edycji danych użytkownika. Po dokonaniu pożądanych zmian należy je zapisać.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Funkcjonalność „Raporty cykliczne”',
    subsections: [
      {
        name: 'Co zawierają raporty cykliczne?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Raporty cykliczne zawierają informacje na temat zmian oraz stanu aktualnego kategorii wiarygodności
                płatniczej, limitu kupieckiego i zadłużenia klientów monitorowanych w serwisie RiskRadar.
              </p>
              <p>
                W raporcie uwzględniani są tylko klienci z uruchomionym monitorowaniem w momencie generowania raportu
                cyklicznego, przy czym:
              </p>
              <ul>
                <li>
                  jeśli monitorowanie klienta zostało włączone w trakcie okresu, za który generowany jest raport
                  cykliczny i wystąpiły zmiany na tym kliencie, to zostaną one wykazane w raporcie cyklicznym,
                </li>
                <li>
                  jeśli monitorowanie klienta zostało wyłączone w trakcie okresu, za który generowany jest raport
                  cykliczny i wystąpiły zmiany na tym kliencie, to nie zostaną one wykazane w raporcie cyklicznym.
                </li>
              </ul>
              <p>Raport cykliczny może być udostępniany:</p>
              <ul>
                <li>tylko poprzez serwis RiskRadar w pliku Excel,</li>
                <li>poprzez serwis RiskRadar w pliku Excel oraz poprzez e-mail w pliku PDF.</li>
              </ul>
              <p>
                Szczegółowe listy (tj. lista zmian na klientach i lista aktualnych klientów) mogą być udostępniane w
                raporcie cyklicznym:
              </p>
              <ul>
                <li>w serwisie RiskRadar w pliku Excel,</li>
                <li>w serwisie RiskRadar w pliku Excel oraz poprzez e-mail w pliku PDF.</li>
              </ul>
              <p>Raport cykliczny udostępniany poprzez serwis RiskRadar w pliku Excel zawiera:</p>
              <ul>
                <li>szczegółową listę zmian za dany okres (np. za tydzień),</li>
                <li>szczegółową listę aktualnych klientów na dany dzień,</li>
                <li>listę ustawień raportu cyklicznego,</li>
                <li>przyciski „Pobierz” do generowania Raportów KG o klientach.</li>
              </ul>
              <p>Raport cykliczny udostępniany poprzez e-mail w pliku PDF zawiera:</p>
              <ul>
                <li>podsumowanie informacji o zmianach za dany okres (np. za tydzień),</li>
                <li>podsumowanie informacji o aktualnej liczbie klientów na dany dzień,</li>
                <li>szczegółową listę zmian za dany okres (np. za tydzień),</li>
                <li>szczegółową listę aktualnych klientów na dany dzień,</li>
                <li>listę ustawień raportu cyklicznego,</li>
                <li>przyciski „Pobierz” do generowania Raportów KG o klientach.</li>
              </ul>
              <p>
                Raport cykliczny w pliku PDF udostępnianym poprzez e-mail może zawierać łącznie maksymalnie 1000
                rekordów (sumaryczna ilość wierszy z danymi we wszystkich sekcjach).
              </p>
              <p>
                E-mail z raportem cyklicznym zawiera link do zakładki „Historia raportów cyklicznych”, w której można
                pobrać pełne dane w formacie Excel.
              </p>
              <p>E-mail z raportem cyklicznym w pliku PDF nie jest wysyłany, gdy:</p>
              <ul>
                <li>
                  w ustawieniach raportu cyklicznego w opcji „Sposób udostępnienia raportu” wybrano „Tylko poprzez
                  serwis”,
                </li>
                <li>dla danego raportu cyklicznego nie ma ani jednego NIP dla żadnej z sekcji z tabeli,</li>
                <li>raport cykliczny nie ma zdefiniowanej listy odbiorców.</li>
              </ul>
              <p>
                Przyciski „Pobierz” zawarte w raportach cyklicznych służą do pobierania Raportów KG o klientach.
                Przyciski te są aktywne przez okres równy częstotliwości raportu cyklicznego. Kliknięcie w nieaktywny
                link spowoduje wyświetlenie komunikatu „Link wygasł lub nie posiadasz uprawnień do pobierania raportów”.
                Aktywny link może być używany wielokrotnie, tj. pobranych będzie tyle Raportów KG, ile kliknięć w link.
                Każdorazowe kliknięcie w przycisk „Pobierz” przy danym kliencie powoduje wygenerowanie Raportu KG na
                jego temat, o ile użytkownik ma nadane uprawnienie do pobierania Raportów KG. Każde pobranie Raportu KG
                o kliencie z poziomu raportu cyklicznego jest rejestrowane w liczniku Raportów KG. Dodatkowe informacje
                na ten temat są zawarte w Samouczku w punkcie „6.4. Jak działa licznik usług?”. Generowanie Raportu KG
                może potrwać 30 sekund. Po zakończeniu generowania Raportu KG jest on prezentowany w przeglądarce. Każdy
                Raport KG o kliencie pobrany z poziomu raportu cyklicznego będzie widoczny w serwisie RiskRadar dla
                innych użytkowników do 7 dni od jego wygenerowania.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak utworzyć nowy raport cykliczny?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Nowy raport cykliczny może utworzyć Administrator firmy w zakładce „Kreator raportów cyklicznych”.</p>
              <p>Utworzenie nowego raportu cyklicznego należy rozpocząć od kliknięcia przycisku „Dodaj nowy raport”.</p>
              <p>W sekcji „Ustawienia ogólne” dostępne są następujące ustawienia (wizualizacja poglądowa):</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-2a.png')}
                  alt="Jak utworzyć nowy cykliczny raport e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>
                W sekcji „Zawartość raportu” dostępne są następujące ustawienia (wizualizacja przedstawia domyślne
                zaawansowane ustawienia raportu):
              </p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/9-2b.png')}
                  alt="Jak utworzyć nowy cykliczny raport e-mail?"
                  className={styles.horizontallImg}
                />
              </div>
              <p>
                Po wprowadzeniu wszystkich wymaganych ustawień należy nacisnąć przycisk „Zapisz raport”. Użycie
                przycisku „Wyczyść” powoduje przywrócenie ustawień domyślnych. Użycie przycisku „Anuluj” powoduje
                anulowanie operacji dodawania nowego raportu i powrót do wcześniejszego ekranu.
              </p>
              <p>
                Pierwszy raport cykliczny po utworzeniu zostanie wygenerowany w najbliższym terminie wpisującym się w
                wybraną częstotliwość. Raporty cykliczne są generowane około godziny 7 rano, zgodnie z ustawioną
                częstotliwością.
              </p>
              <p>
                Częstotliwość raportu cyklicznego definiuje jednocześnie okres, za który wykazywane będą zmiany w
                raporcie (o ile wskazano w ustawieniach, że raport ma uwzględniać również zmiany).
              </p>
              <p>
                Przykładowo, jeśli 4 kwietnia (czwartek) utworzono nowy raport cykliczny i ustawiono jego częstotliwość
                co tydzień, to po raz pierwszy raport ten zostanie wygenerowany 8 kwietnia (poniedziałek) około godziny
                7 rano. Kolejny raport zostanie wysłany 15 kwietnia (poniedziałek) około 7 rano i będzie zawierać listę
                zmian za okres 8-14 kwietnia oraz listę klientów według stanu na 15 kwietnia (o ile w ustawieniach
                zaznaczono, że raport ma uwzględniać zarówno wykaz zmian jak i aktualne listy klientów).
              </p>
              <p>
                Nie ma ograniczeń w zakresie liczby raportów cyklicznych, które można utworzyć w ramach danej firmy.{' '}
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak zmodyfikować istniejący raport cykliczny?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Istniejący raport cykliczny można zmodyfikować, np. zmienić listę odbiorców czy ustawienia dotyczące
                zawartości. W tym celu w zakładce „Kreator raportów cyklicznych” należy wybrać z listy właściwy raport
                cykliczny i następnie kliknąć ikonę ustawień i wybrać opcję „Modyfikuj”. Spowoduje to otworzenie okna z
                ustawieniami danego raportu cyklicznego. Po wprowadzeniu zmian należy je zapisać za pomocą przycisku
                „Zapisz raport”.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Czy użytkownik może dostawać raport cykliczny na e-mail a nie mieć dostępu do RiskRadar?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>Tak, jest to możliwe.</p>
              <p>
                W tym celu należy dodać nowego użytkownika typu „Użytkownik bez dostępu do serwisu” a następnie dodać
                tego użytkownika do listy odbiorców danego raportu cyklicznego.
              </p>
              <p>
                Użytkownik ten będzie otrzymywać raport cykliczny na swój e-mail pod warunkiem, że w ustawieniach tego
                raportu w opcji „Sposób udostępnienia raportu” zaznaczona jest pozycja „Poprzez serwis RiskRadar oraz
                e-mail”.
              </p>
              <p>
                Ponadto, na etapie nadawania uprawnień temu użytkownikowi, można także określić, czy ma on mieć
                możliwość pobierania aktualnych Raportów KG o klientach (z poziomu raportu cyklicznego) czy nie.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Pobranie raportu o kliencie z raportu cyklicznego a pobranie go z serwisu RiskRadar.',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Pod względem zakresu danych Raport KG o kliencie pobrany z poziomu raportu cyklicznego jest identyczny
                jak ten pobrany z poziomu serwisu RiskRadar. Pobrane Raporty KG o klientach są dostępne w serwisie
                RiskRadar przez 7 dni niezależnie od tego, czy pobranie nastąpiło z poziomu raportu cyklicznego czy z
                poziomu serwisu RiskRadar.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Jak sprawdzić, jakie cykliczne raporty otrzymuje dany użytkownik?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Może tego dokonać tylko Administrator firmy wchodząc do zakładki „Użytkownicy i uprawnienia” i
                otwierając kartę danego użytkownika. Na karcie użytkownika znajduje się sekcja „Raporty cykliczne
                e-mail” zawierająca nazwy raportów cyklicznych, których odbiorcą jest dany użytkownik.
              </p>
            </div>
          );
        }
      },
      {
        name: 'Co zawiera zakładka „Historia raportów cyklicznych”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                „Historia raportów cyklicznych” to funkcjonalność prezentująca listę wszystkich raportów cyklicznych
                wygenerowanych w serwisie RiskRadar na zlecenie danej firmy zgodnie z określonymi ustawieniami.
              </p>
              <p>Lista zawiera następujące informacje i opcje:</p>
              <ul>
                <li>Nazwa raportu,</li>
                <li>Data wygenerowania,</li>
                <li>Właściciel raportu,</li>
                <li>Częstotliwość wysyłania raportu, </li>
                <li>Ikony w kolumnie „Pobierz” do pobierania plików Excel z danymi.</li>
              </ul>
              <p>Pozycje na liście są widoczne przez 2 miesiące. Po tym czasie są usuwane.</p>
              <p>
                Link do zakładki „Historia raportów cyklicznych” jest zawarty w e-mailu powiadamiającym o wygenerowaniu
                nowego raportu cyklicznego.
              </p>
              <p>
                Zakładka oraz treści w „Historii raportów cyklicznych” są widoczne dla wszystkich aktywnych użytkowników
                RiskRadar w ramach danej firmy w roli „Pracownik” lub „Administrator”.
              </p>
              <p>Raport cykliczny udostępniany poprzez serwis RiskRadar w pliku Excel zawiera:</p>
              <ul>
                <li>szczegółową listę zmian za dany okres (np. za tydzień),</li>
                <li>szczegółową listę aktualnych klientów na dany dzień,</li>
                <li>listę ustawień wygenerowanego raportu cyklicznego,</li>
                <li>przyciski „Pobierz” do generowania Raportów KG o klientach.</li>
              </ul>
              <p>
                Raport cykliczny w pliku Excel w "Historii raportów cyklicznych” jest generowany zgodnie z ustawieniami
                i niezależnie od raportu wysyłanego poprzez e-mail.
              </p>
              <p>
                Raport cykliczny w pliku Excel nie jest generowany, jeśli jest pusty - np. ustawiono wyłącznie
                uwzględnianie zmian (bez aktualnych list klientów) a w danym okresie nie wystąpiły żadne zmiany na
                monitorowanych klientach.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Funkcjonalność „Historia zmian na klientach”',
    subsections: [
      {
        name: 'Jakie dane zawiera zakładka „Historia zmian na klientach”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W zakładce „Historia zmian na klientach” prezentowane są dane dotyczące klientów z aktualnie włączonym
                monitorowaniem, dla których w ostatnich 9 tygodniach (tj. tydzień bieżący i 8 tygodni wstecz) wystąpiły
                zmiany salda zadłużenia w KRD i/lub kategorii wiarygodności płatniczej i/lub kwoty limitu kupieckiego.
                Wartości w poszczególnych polach pokazują stan (wartość) po wystąpieniu zmiany.{' '}
              </p>
              <p>
                Puste pole oznacza, że danego dnia nie wystąpiła zmiana. Domyślnie prezentowany jest „Widok sumaryczny”
                zawierający podsumowanie wszystkich tygodni.{' '}
              </p>
              <p>
                Kolumna „Dziś” jest zawsze widoczna i prezentuje dane na dzień bieżący. Wartości są w niej widoczne,
                jeżeli występuje zadłużenie i/lub jest wyznaczona kategoria wiarygodności i/lub jest wyznaczony limit
                kupiecki.
              </p>
              <p>
                W przypadku, gdy usługa wyznaczania limitu kupieckiego nie jest aktywna na koncie danej firmy w
                RiskRadar, to wiersze dotyczące limitu kupieckiego zawierają informację „Nieaktywny”.
              </p>
              <p>
                Jeżeli monitorowanie zostanie wyłączone, historia zmian salda zadłużenia, kategorii wiarygodności i
                limitu kupieckiego zostanie usunięta z serwisu RiskRadar.{' '}
              </p>
              <p>Poglądowa wizualizacja ekranu z widokiem sumarycznym historii zmian:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/10-1.png')}
                  alt="Jakie dane zawiera zakładka „Historia zmian”?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      },
      {
        name: 'Jak działa eksport danych do CSV w zakładce „Historia zmian na klientach”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Funkcjonalność eksportu danych do CSV w zakładce „Historia zmian na klientach” umożliwia wyeksportowanie
                danych do pliku CSV i następnie ich analizę poza serwisem RiskRadar. Po naciśnięciu przycisku „Eksport
                do CSV” rozpoczyna się tworzenie pliku CSV z danymi widocznymi na ekranie „Historia zmian”. Plik zawiera
                dane z tego ekranu z uwzględnieniem filtra „Rodzaj informacji”, wyszukiwania, sortowania, ze wszystkich
                stron, z okresu 9 tygodni (tydzień bieżący i 8 tygodni wstecz). W eksporcie nie jest uwzględniany filtr
                „Zakres czasowy”. Dane w pliku są rozdzielone średnikami.
              </p>
              <p>
                Dane w pliku są w następującej kolejności:
                <ul>
                  <li>Nazwa firmy,</li>
                  <li>NIP,</li>
                  <li>Rodzaj informacji – „Zadłużenie w KRD” lub „Wiarygodność” lub „Limit kupiecki”,</li>
                  <li>Dzień tygodnia i data,</li>
                  <li>Dziś i data bieżąca.</li>
                </ul>
              </p>
              <p>
                Użytkownik, który zainicjował eksport otrzyma powiadomienie e-mail, gdy plik będzie gotowy do pobrania.
              </p>
              <p>
                Plik CSV z wyeksportowanymi danymi jest dostępny do pobrania dla wszystkich użytkowników danej firmy w
                zakładce menu "Historia eksportu danych" przez 7 dni.
              </p>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Funkcjonalność „Historia pobranych raportów”',
    subsections: [
      {
        name: 'Co zawiera zakładka „Historia pobranych raportów”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Zakładka ta zawiera informacje o wszystkich Raportach KG o klientach pobranych przez wszystkich
                użytkowników z danej firmy w ciągu ostatnich 7 dni. Lista zawiera zarówno Raporty KG pobrane z poziomu
                serwisu RiskRadar (z listy klientów i z kart klientów) jak i z poziomu raportów cyklicznych. Dodatkowo
                na liście uwzględniane są Raporty KG, które są w trakcie generowania (generują się zwykle około 30
                sekund). Po wygenerowaniu się Raportu KG jego status zmienia się samoczynnie, bez konieczności
                odświeżania strony. Raport KG, który został wygenerowany można pobrać klikając na dowolne miejsce w
                wierszu dotyczącym danego klienta. Pobranie uprzednio wygenerowanego Raportu KG można wykonywać dowolną
                ilość razy i nie skutkuje to naliczeniem usługi.
              </p>
              <p>Wizualizacja poglądowa ekranu z historią pobranych Raportów KG:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/11-1.png')}
                  alt="Co zawiera zakładka „Historia raportów”?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Funkcjonalność „Nowy raport o dowolnym NIP”',
    subsections: [
      {
        name: 'Do czego służy i jak działa przycisk „Nowy raport o dowolnym NIP”?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                Przycisk ten służy do szybkiego pobierania Raportu KG o dowolnym kliencie, także takim, którego nie ma w
                RiskRadar w danej chwili. Kliknięcie tego przycisku na dowolnym ekranie serwisu powoduje otwarcie
                zakładki „Historia pobranych raportów” oraz wyświetlenie okna dialogowego do wprowadzania numeru NIP.
                Numer NIP można wprowadzić ręcznie lub korzystając z podpowiedzi w wyszukiwarce (w polu „Wyszukaj
                klienta po NIP lub nazwie” należy wpisać minimum 3 znaki).
              </p>
              <p>
                W przypadku użytkownika typu „Administrator” i „Pracownik”, jeśli wprowadzony zostanie poprawny numer
                NIP i klient istnieje już w RiskRadar, to od razu nastąpi wygenerowanie aktualnego Raportu KG o kliencie
                oraz naliczenie usługi. Jeśli jednak klient nie istnieje w RiskRadar to użytkownik decyduje czy pobiera
                raport i dodaje klienta do listy klientów, czy pobiera raport bez dodawania klienta do listy klientów.
                Po dokonaniu tego wyboru nastąpi wygenerowanie aktualnego Raportu KG o tym kliencie i naliczenie usługi.
              </p>
              <p>
                W przypadku „Użytkownika tylko raportów o NIP” nie następuje natomiast weryfikacja, czy dany NIP jest na
                liście klientów w RR czy nie. Od razu następuje przejście do generowania raportu o tym NIPie (bez
                dodawania go do listy klientów w RR) oraz naliczenie usługi.
              </p>
              <p>
                Wygenerowany Raport KG pojawi się na liście „Historia raportów” i stamtąd można go pobrać i otworzyć
                jako PDF na urządzeniu końcowym. Więcej informacji o zakładce „Historia pobranych raportów” znajduje się
                w Samouczku w punkcie „11.1. Co zawiera zakładka „Historia pobranych raportów”?”.
              </p>
              <p>Wizualizacja poglądowa okna dialogowego do pobierania Raportu KG o dowolnym NIP:</p>
              <div className={styles.tutorialImageWrapper}>
                <img
                  src={require('../../../assets/images/tutorial/12-1.png')}
                  alt="Do czego służy i jak działa przycisk „Nowy raport o kliencie”?"
                  className={styles.horizontallImg}
                />
              </div>
            </div>
          );
        }
      }
    ]
  },
  {
    name: 'Pomoc',
    subsections: [
      {
        name: 'Z kim i jak się kontaktować w przypadku pytań?',
        content: function (): JSX.Element {
          return (
            <div className={styles.tutorialSubSectionWrapper}>
              <p>
                W pierwszej kolejności sprawdź, czy odpowiedź na pytanie znajduje się w Samouczku. Zawiera on szereg
                wartościowych wskazówek i objaśnień, które ułatwiają korzystanie z RiskRadar.
              </p>
              <p>Jeśli pytanie dotyczy:</p>
              <ul>
                <li>zarządzania kontami użytkowników, np. dodawania, usuwania kont,</li>
                <li>zarządzania uprawnieniami użytkowników do funkcjonalności i usług,</li>
                <li>cyklicznych raportów,</li>
                <li>klientów dodanych do RiskRadar,</li>
              </ul>
              <p>należy skontaktować się z administratorem RiskRadar w danej firmie.</p>
              <p>Jeśli pytanie dotyczy:</p>
              <ul>
                <li>działania serwisu RiskRadar,</li>
                <li>działania usług,</li>
                <li>limitów usług i opłaty miesięcznej, </li>
                <li>fakturowania i płatności,</li>
              </ul>
              <p>należy skontaktować się bezpośrednio z opiekunem danej firmy w KG lub z infolinią KG:</p>
              <ul>
                <li>telefonicznie – pod numerem 71-74-74-744,</li>
                <li>
                  pocztą elektroniczną – pod adresem{' '}
                  <a className={styles.blueLink} href="mailto:pomoc@riskradar.pl">
                    pomoc@riskradar.pl
                  </a>
                  .
                </li>
              </ul>
              <p>
                Jeśli sprawa dotyczyć będzie usług świadczonych przez KRD BIG S.A. zawartych w RiskRadar (tj. zdarzeń z
                monitorowania, zadłużenia, kategorii wiarygodności płatniczej, limitu kupieckiego), to rozmowa zostanie
                przekierowana do oddzielnej infolinii obsługiwanej przez KRD BIG S.A.
              </p>
            </div>
          );
        }
      }
    ]
  }
].map((s, index) => ({
  ...s,
  index: `${index + 1}.`,
  subsections: s.subsections.map((ss, index2) => ({
    ...ss,
    index: `${index + 1}.${index2 + 1}.`,
    id: `punkt-${index + 1}-${index2 + 1}`
  }))
}));
