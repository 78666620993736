import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';

if (process.env.REACT_APP_REDIRECT_URI === 'https://panel.riskradar.pl') {
  const gtmHeadStartComment = document.createComment(' Google Tag Manager ');
  const gtmHeadEndComment = document.createComment(' End Google Tag Manager ');

  const script = document.createElement('script');
  script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-WFQSBMNJ');`;

  document.head.insertBefore(gtmHeadEndComment, document.head.firstElementChild);
  document.head.insertBefore(script, gtmHeadEndComment);
  document.head.insertBefore(gtmHeadStartComment, document.head.firstElementChild);

  const gtmBodyStartComment = document.createComment(' Google Tag Manager (noscript) ');
  const gtmBodyEndComment = document.createComment(' End Google Tag Manager (noscript) ');

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-WFQSBMNJ"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe`;

  document.body.insertBefore(gtmBodyEndComment, document.body.firstElementChild);
  document.body.insertBefore(noscript, gtmBodyEndComment);
  document.body.insertBefore(gtmBodyStartComment, document.body.firstElementChild);
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Suspense fallback="loading">
        <App />
      </Suspense>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
