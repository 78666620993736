import { ComponentType, FC, useEffect, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { Dialog, Spinner } from 'components';
import { PATH } from 'router/routes';
import { ROLE } from 'config/oidc';
import { ROUTE_TYPE } from 'types';
import { getCuratorCompanies as getCuratorCompaniesService } from 'api/services';
import { Box } from '@mui/system';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { debounce } from 'lodash';
import MenuItem from '@mui/material/MenuItem';

import Autocomplete from '@mui/material/Autocomplete';
import { CuratorCompanyDto } from 'api/models';
import { LOCAL_STORAGE_ACTIONS } from 'components/ActivityDetector/ActivityDetector.types';

interface ProtectedRouteProps {
  component: ComponentType;
  roles: ROLE[];
  hideSpinner?: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: RouteComponent, roles, hideSpinner }) => {
  const [curatorCompanies, setCuratorCompanies] = useState<CuratorCompanyDto[]>([]);
  const [curatorCompaniesList, setCuratorCompaniesList] = useState<CuratorCompanyDto[]>([]);
  const [isCurator, setIsCurator] = useState<boolean>(false);
  const [showCompanySelectModal, setShowCompanySelectModal] = useState<boolean>(false);
  const [canDisplayContent, setCanDisplayContent] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<null | number>(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const auth = useAuth();

  useEffect(() => {
    localStorage.setItem('routeType', ROUTE_TYPE.PROTECTED);

    const getCurators = async () => {
      const curators = await getCuratorCompaniesService();
      const { companies } = curators.data.Result;

      if (companies?.length > 0) {
        setSelectedCompanyName(companies[0].name);
      }

      if (companies?.length === 1) {
        localStorage.setItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_ID, companies[0]?.id?.toString());
        localStorage.setItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_NAME, companies[0]?.name?.toString());
        setCanDisplayContent(true);
      } else {
        setCuratorCompanies(companies);
        setShowCompanySelectModal(true);
        setCuratorCompaniesList(companies);
      }
    };

    if (auth?.userData?.profile?.Role === ROLE.CURATOR) {
      setIsCurator(true);
      getCurators();
    } else {
      setCanDisplayContent(true);
    }

    if (
      auth?.userData?.profile?.Role === ROLE.CURATOR &&
      localStorage.getItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_ID)
    ) {
      setCanDisplayContent(true);
    }
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      if (!auth?.isLoading && !auth?.userData) {
        try {
          await auth.userManager?.signinSilent();
        } catch (e: any) {
          if (e.error === 'login_required') {
            await auth.signIn();
          }
        }
      }
    };

    const authToken = localStorage.getItem(LOCAL_STORAGE_ACTIONS.AUTH_TOKEN);

    const detectNoMatchingState = async () => {
      if (auth?.isLoading && !auth?.userData && !authToken) {
        try {
          await auth.userManager?.querySessionStatus();
        } catch (e: any) {
          if (e.error === 'login_required') {
            await auth.signIn();
          }
        }
      }
    };

    authenticate();
    detectNoMatchingState();
  }, [auth, auth?.isLoading, auth?.userData, auth?.userManager]);

  const handleCompanySelect = (id: number) => {
    const name = curatorCompanies.filter((company) => company.id === id)[0].name;
    setSelectedCompanyId(id);
    setSelectedCompanyName(name);
    setInputValue(name);
    setOpen(false);
  };

  const selectCompany = () => {
    if (curatorCompanies?.length === 0) {
      auth.userManager.signoutRedirect({
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI
      });
    } else {
      if (selectedCompanyId) {
        localStorage.setItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_ID, selectedCompanyId.toString());
        localStorage.setItem(LOCAL_STORAGE_ACTIONS.CURATOR_SELECTED_COMPANY_NAME, selectedCompanyName);
        setCanDisplayContent(true);
        setShowCompanySelectModal(false);
      }
    }
  };

  const parsedOptions = curatorCompaniesList.map((el) => ({ label: el.name, id: el.id }));

  const getCuratorCompanies = async (value: string) => {
    if (value.length >= 3) {
      const curators = await getCuratorCompaniesService(value);
      const { companies } = curators.data.Result;
      setCuratorCompaniesList(companies);
    }
  };

  const debounceGetCuratorCompanies = useCallback(debounce(getCuratorCompanies, 700), []);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    debounceGetCuratorCompanies(value);
    if (!value) {
      setCuratorCompaniesList(curatorCompanies);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (!selectedCompanyId) {
      setInputValue('');
    } else if (selectedCompanyId && !value) {
      setInputValue('');
      setSelectedCompanyId(null);
      setSelectedCompanyName('');
    } else if (selectedCompanyId) {
      setInputValue(selectedCompanyName);
    }

    setCuratorCompaniesList(curatorCompanies);
  };

  if (isCurator && !canDisplayContent) {
    return (
      <>
        <Dialog
          isOpen={showCompanySelectModal}
          closeModal={() => {}}
          handlePrimaryAction={selectCompany}
          primaryLabel={curatorCompanies?.length === 0 ? 'Wyloguj' : 'Zatwierdź'}
          closeLabel=""
          primaryDisabled={!selectedCompanyId && curatorCompanies?.length !== 0}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                color: '#000',
                fontSize: '1.2rem',
                justifyContent: 'center',
                textAlign: 'center',
                margin: '0 0 1rem 0',
                fontWeight: 'bold',
                width: '100%'
              }}>
              Wybierz firmę
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                margin: '0 0 1.2rem 0',
                width: '100%'
              }}>
              Wszystkie operacje w RiskRadar będą wykonywane w kontekście wybranej firmy.
            </Box>
            <Box>
              <FormControl fullWidth sx={{ justifyContent: 'center' }}>
                <Autocomplete
                  options={parsedOptions}
                  open={open}
                  includeInputInList
                  inputValue={inputValue}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  noOptionsText={''}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onBlur={onBlur}
                  sx={{
                    width: '100%',
                    color: 'rgba(0, 0, 0, 0.87)'
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="success"
                      label={open || selectedCompanyId ? 'Firma' : 'Wyszukaj po nazwie firmy lub NIP'}
                      onChange={(event) => handleInputChange(event.target.value)}
                      onClick={() => setOpen(true)}
                      sx={{
                        width: '100%',
                        color: 'rgba(0, 0, 0, 0.87)'
                      }}
                      onBlur={onBlur}
                    />
                  )}
                  renderOption={(_, option: { id: number; label: string }) => {
                    return (
                      <MenuItem onClick={() => handleCompanySelect(option.id)} key={option.id}>
                        {option.label}
                      </MenuItem>
                    );
                  }}
                  disableClearable
                  filterOptions={(x) => x}
                  value={{ id: Number(selectedCompanyId), label: selectedCompanyName }}
                />
              </FormControl>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }

  const hasRequiredRole = roles.includes(auth?.userData?.profile?.Role);

  if (hasRequiredRole) {
    return <RouteComponent />;
  }

  if (auth?.userData?.profile?.Role === ROLE.TECH_ACCOUNT) {
    return <Navigate to={PATH.ACCESS_DENIED} replace={true} />;
  }

  if (auth?.userData) {
    return <Navigate to={PATH.ACCESS_DENIED} replace={true} />;
  }

  return hideSpinner ? null : <Spinner />;
};

export default ProtectedRoute;
