import { FC } from 'react';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Loader from '../Loader';
import { Props } from './Dialog.types';
import { StyledButton } from 'components';
import classes from './styles.module.scss';
import { SxProps } from '@mui/material';

const Dialog: FC<Props> = ({
  children,
  title,
  isOpen,
  isPending = false,
  primaryLabel = 'Potwierdzam',
  secondaryLabel = '',
  closeLabel = 'Anuluj',
  closeModal,
  handlePrimaryAction,
  handleSecondaryAction,
  disableBackdropClick,
  disableEscapeKeyDown,
  primaryDisabled,
  secondaryDisabled,
  dialogActionsJustify,
  dialogActionsWidth,
  dialogMaxWidth
}) => {
  const handleClose = (event: any, reason: string) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }

    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }

    if (closeModal instanceof Function) {
      closeModal();
    }
  };

  const buttonStyle: SxProps = {
    whiteSpace: 'pre-wrap',
    lineHeight: '1rem'
  };

  return (
    <MuiDialog
      maxWidth={dialogMaxWidth ? dialogMaxWidth : 'xl'}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={title && 'alert-dialog-title'}
      aria-describedby="alert-dialog-description"
      sx={{ minWidth: '19rem', minHeight: '9rem' }}
      disableScrollLock>
      <Loader isLoading={isPending}>
        <div className={classes.container}>
          {title && (
            <div className={classes.title} id="alert-dialog-title">
              {title}
            </div>
          )}

          <DialogContent>
            <DialogContentText component={'div'} id="alert-dialog-description">
              {children}
            </DialogContentText>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: dialogActionsJustify ? dialogActionsJustify : 'flex-start',
              width: dialogActionsWidth ? '100%' : 'auto',
              padding: closeLabel === '' && primaryLabel === '' ? '' : '0.5rem 1rem'
            }}>
            {closeLabel !== '' && (
              <StyledButton onClick={closeModal} sx={buttonStyle}>
                {closeLabel}
              </StyledButton>
            )}
            {secondaryLabel !== '' && handleSecondaryAction instanceof Function && (
              <StyledButton onClick={handleSecondaryAction} disabled={secondaryDisabled} sx={buttonStyle}>
                {secondaryLabel}
              </StyledButton>
            )}
            {primaryLabel !== '' && handlePrimaryAction instanceof Function && (
              <StyledButton onClick={handlePrimaryAction} disabled={primaryDisabled} sx={buttonStyle}>
                {primaryLabel}
              </StyledButton>
            )}
          </DialogActions>
        </div>
      </Loader>
    </MuiDialog>
  );
};

export default Dialog;
