import { FC, useState, useCallback, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from 'components';
import { useSendRequest, useToggle } from 'lib/hooks';
import { PATH } from 'router/routes';
import { deleteReport } from 'api/services';
import { Props } from './ListMenu.types';

const ListMenu: FC<Props> = ({ id, fetchReports, isRemoveDisabled }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalOpen, toggleModal] = useToggle();

  const { isLoading, sendData } = useSendRequest();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const submitCallback = useCallback(() => {
    toggleModal();
    fetchReports();
  }, [fetchReports, toggleModal]);

  const deleteHandler = useCallback(() => deleteReport(id), [id]);

  const removeReport = () => sendData(deleteHandler, submitCallback, toggleModal);

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{
          ml: 2,
          background: anchorEl ? 'rgba(19, 103, 229, 0.09)' : 'transparent',
          padding: '0.5rem'
        }}
        aria-controls={anchorEl ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}>
        <SettingsIcon color={anchorEl ? 'primary' : 'inherit'} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        disableScrollLock
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Link to={`${PATH.EDIT_REPORT}/${id}`} target="_blank" rel="noopener noreferrer">
          <MenuItem>
            <EditIcon fontSize="small" />
            <Typography variant="body1" sx={{ marginLeft: '0.75rem' }}>
              Modyfikuj
            </Typography>
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={toggleModal} disabled={isRemoveDisabled}>
          <CloseIcon fontSize="small" />
          <Typography variant="body1" sx={{ marginLeft: '0.75rem' }}>
            Usuń
          </Typography>
        </MenuItem>
      </Menu>

      <Dialog
        isOpen={isModalOpen}
        isPending={isLoading}
        closeModal={toggleModal}
        handlePrimaryAction={removeReport}
        primaryLabel="Tak"
        closeLabel="Nie">
        Czy chcesz usunąć raport?
      </Dialog>
    </>
  );
};

export default ListMenu;
