import { logoBlack } from 'assets/images/logo';
import styles from './generatingReport.module.scss';
import { useEffect, useState } from 'react';
import { useSendRequest } from 'lib/hooks';
import { getReportConfirmationSetting } from 'api/services';
import { useSearchParams } from 'react-router-dom';
import ReportPlusGenerator from './ReportPlusGenerator';
import { Dialog } from 'components';
import { ReportConfirmationByTokenDto } from 'api/models';
import { CircularProgress } from '@mui/material';

const GeneratingReport = () => {
  const [needVerification, setNeedVerification] = useState<boolean | null>(null);
  const [generateReport, setGenerateReport] = useState(false);
  const [canCloseTab, setCanCloseTab] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const {
    result: reportConfiguration,
    errorMessage,
    sendData,
    isLoading
  } = useSendRequest<ReportConfirmationByTokenDto>();

  const reportNeedVerification = async () => {
    const tokenParam = searchParams.get('token') ?? '';
    sendData(() => getReportConfirmationSetting(tokenParam));
  };

  const goToPreviousTab = () => {
    if (window.history.length > 1) {
      window.history.back();
    }
  };

  const noButtonAction = () => {
    goToPreviousTab();
    setCanCloseTab(true);
  };

  const yesButtonAction = () => {
    setGenerateReport(true);
    setNeedVerification(false);
  };

  useEffect(() => {
    reportNeedVerification();
  }, []);

  useEffect(() => {
    if (reportConfiguration) {
      const needVerificationWindow = reportConfiguration.isConfirmationRequired!;
      setNeedVerification(needVerificationWindow);
      if (!needVerificationWindow) {
        setGenerateReport(true);
      }
    }
  }, [reportConfiguration, isLoading]);

  if (errorMessage && errorMessage.length) {
    return (
      <div className={styles.generatingReportBox}>
        <div className={styles.generatingReportTop}>
          <div className={styles.generatingReportTopLeft}></div>
          <img src={logoBlack} alt="Logo" className={styles.generatingReportLogo} />
          <div className={styles.generatingReportTopRight}></div>
        </div>
        <div className={styles.generatingReportBottom}>
          <div className={styles.generatingReportBottomLeft}>
            <div className={styles.generatingReportError}>!</div>
          </div>
          <div className={styles.generatingReportBottomRight}>
            <span className={styles.generatingReportBottomMessageError}>
              Link do raportu wygasł lub nie posiadasz uprawnień do pobierania raportów.
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <CircularProgress />{' '}
        </div>
      )}
      {canCloseTab && (
        <Dialog isOpen={true} closeLabel="">
          <div className={styles.generatingReportLogoTopMiddle}>
            <img src={logoBlack} alt="Logo" className={styles.generatingReportLogo} />
          </div>
          Anulowano żądanie wygenerowania aktualnego raportu o kliencie. <br />
          Raport o kliencie nie zostanie wygenerowany.
          <br />
          Można teraz zamknąć tę zakładkę przeglądarki.
        </Dialog>
      )}
      {needVerification && !canCloseTab && (
        <Dialog
          isOpen={needVerification}
          closeModal={noButtonAction}
          handlePrimaryAction={yesButtonAction}
          primaryLabel="Tak"
          closeLabel="Nie">
          <div className={styles.generatingReportLogoTopMiddle}>
            <img src={logoBlack} alt="Logo" className={styles.generatingReportLogo} />
          </div>
          Czy potwierdzasz żądanie wygenerowania aktualnego raportu o tym kliencie? <br />
          NIP klienta: {searchParams.get('taxId') ?? ''}
        </Dialog>
      )}
      {generateReport && <ReportPlusGenerator />}
    </>
  );
};

export default GeneratingReport;
