import { logoBlack } from 'assets/images/logo';
import styles from './generatingReport.module.scss';
import FadeLoader from 'react-spinners/FadeLoader';
import { useEffect, useState } from 'react';
import { useInterval, useSendRequest } from 'lib/hooks';
import { generateReport, reportStatus, getGeneratedReport } from 'api/services';
import { useSearchParams } from 'react-router-dom';
import { REPORT_STATE_MESSAGE } from 'api/models/ReportsDto';

const ReportPlusGenerator = () => {
  const [reportGenerated, setReportGenerated] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<any>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [attempt, setAttempt] = useState(0);
  const [shouldRetry, setShouldRetry] = useState<boolean>(true);
  const [delay, setDelay] = useState(6000);
  const [searchParams, setSearchParams] = useSearchParams();
  const { result, isLoading, errorMessage, sendData } = useSendRequest();
  const { result: reportResult, sendData: sendReportData } = useSendRequest<any>();

  const getReport = async () => {
    sendData(() => generateReport(searchParams.get('taxId') ?? '', searchParams.get('token') ?? ''));
  };

  const getReportStatus = async (id: number, token: string) => {
    sendReportData(() => reportStatus(id, token));
  };

  useEffect(() => {
    getReport();
  }, []);

  useInterval(
    () => {
      getReportStatus(Number(result), searchParams.get('token') ?? '');
    },
    shouldRetry && result ? delay : null
  );

  useEffect(() => {
    if (errorMessage.find((el) => el.ErrorCode === REPORT_STATE_MESSAGE.NO_ACCESS)) {
      setIsActive(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    const getReport = async () => {
      const report = await getGeneratedReport(reportResult?.reportRequestId, searchParams.get('token') ?? '');

      setPdfData(report);
      setReportGenerated(true);
    };

    if (reportResult) {
      if (reportResult?.isReady === true) {
        setShouldRetry(false);
        getReport();
      }
      setAttempt((attempt) => attempt + 1);
    }
  }, [reportResult]);

  if (reportGenerated) {
    window.location.href = pdfData.config.url;
  }

  return (
    <div className={styles.generatingReportBox}>
      <div className={styles.generatingReportTop}>
        <div className={styles.generatingReportTopLeft}>
          {isActive ? 'Trwa generowanie raportu' : 'Pobieranie raportu'}
        </div>
        <div className={styles.generatingReportTopRight}>
          <img src={logoBlack} alt="Logo" className={styles.generatingReportLogo} />
        </div>
      </div>
      <div className={styles.generatingReportBottom}>
        {isActive ? (
          <>
            <div className={styles.generatingReportBottomLeft}>
              <FadeLoader color="#078A51" />
            </div>
            <div className={styles.generatingReportBottomRight}>
              <span className={styles.generatingReportBottomMessage1}>
                Może to potrwać 30 sekund. W tym czasie pobieramy aktualne informacje z wielu źródeł.
              </span>
              <span className={styles.generatingReportBottomMessage2}>
                Nie odświeżaj strony i nie zamykaj przeglądarki.
              </span>
            </div>
          </>
        ) : (
          <>
            <div className={styles.generatingReportBottomLeft}>
              <div className={styles.generatingReportError}>!</div>
            </div>
            <div className={styles.generatingReportBottomRight}>
              <span className={styles.generatingReportBottomMessageError}>
                Link do raportu wygasł lub nie posiadasz uprawnień do pobierania raportów.
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportPlusGenerator;
