import { Dialog } from 'components';
import { ExportModalProps } from './ExportModal.types';
import { FC, memo } from 'react';
import classes from './ExportModal.module.scss';

const ExportModal: FC<ExportModalProps> = memo(({ isExportModalVisible, closeExportModal }) => {
  return (
    <Dialog isOpen={isExportModalVisible} closeModal={closeExportModal} closeLabel="Zamknij" primaryLabel="">
      <div className={classes.header} data-test-id="history-changes-export-modal-title">
        Rozpoczęcie eksportu danych do pliku csv
      </div>
      Plik csv z wyeksportowanymi danymi z ekranu "Historia zmian" będzie dostępny w zakładce menu "Eksport danych".
      <br />
      Otrzymasz powiadomienie email, gdy plik będzie gotowy do pobrania.
    </Dialog>
  );
});

export default ExportModal;
