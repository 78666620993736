import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ButtonProps } from '@mui/material/Button';
import { PageTitle, TextField, Dialog, StyledButton } from 'components';
import { Props as DialogProps } from 'components/Dialog/Dialog.types';
import { PATH } from 'router/routes';
import { useSendRequest, useToggle, useFetch } from 'lib/hooks';
import { setSuccessMessage } from 'store/success';
import { ClientFormTypes } from '../types';
import { polishClientSchema, euClientSchema } from '../schema';
import { clearPolishTaxNumber } from 'lib/utils';
import { onlyDigitsRegex, polishTaxNumberRegex } from 'regex';
import { useAuth } from 'oidc-react';
import { ROLE } from 'config/oidc';
import { FIELDS } from '../types/clientForm.types';
import Autocomplete from 'components/Autocomplete';
import { AutocompleteDto } from 'api/models/AutocompleteDto';
import { ClientAutocompleteQueryResultDto } from 'api/models';
import { getClientAutocomplete } from 'api/services';
import { tooltipImportInProgress } from 'domains/customer/customerProfile';
import { ButtonWithTooltip } from 'components';
import { ButtonWithTooltipProps } from 'components/ButtonWithTooltip';

const ClientForm: FC<ClientFormTypes.Props> = ({ inEditMode, initialValues, submitHandler, isEditable }) => {
  const [currentTaxNumber, setCurrentTaxNumber] = useState<string>('');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [inputValue, setInputValue] = useState<AutocompleteDto | null>(null);
  const [isAcceptModalOpen, toggleAcceptModal] = useToggle();
  const [isCancelModalOpen, toggleCancelModal] = useToggle();
  const [canPostData] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const curatorSelectedCompanyId = localStorage.getItem('curatorSelectedCompanyId');
  const auth = useAuth();
  const userRole = auth?.userData?.profile?.Role;
  const companyId =
    userRole === ROLE.CURATOR && curatorSelectedCompanyId ? curatorSelectedCompanyId : auth?.userData?.profile?.Company;

  const {
    result: postResult,
    isLoading: isDataSending,
    validationErrors,
    sendData,
    setValidationErrors
  } = useSendRequest();

  const submitCallback = useCallback(() => {
    if (!inEditMode) {
      if (canPostData) {
        dispatch(setSuccessMessage('Dane zostały zapisane'));
      }
    } else {
      navigate(`${PATH.CUSTOMER_PROFILE}/${id}`);
    }
  }, [id, inEditMode, canPostData, navigate, dispatch]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema:
      !currentTaxNumber ||
      currentTaxNumber.startsWith('PL') ||
      currentTaxNumber.match(polishTaxNumberRegex) ||
      currentTaxNumber.match(onlyDigitsRegex) ||
      (Number.isNaN(Number(currentTaxNumber.charAt(0))) && !Number.isNaN(Number(currentTaxNumber.charAt(1)))) ||
      !/\S/.test(currentTaxNumber) ||
      /^\d/.test(currentTaxNumber)
        ? polishClientSchema
        : euClientSchema(currentTaxNumber?.substring(0, 2)),
    onSubmit: (values) => {
      const valuesWithCompany = { ...values, CompanyId: companyId };

      sendData(
        () => submitHandler(valuesWithCompany),
        submitCallback,
        inEditMode
          ? () => {
              toggleAcceptModal();
            }
          : undefined
      );
    }
  });

  useEffect(() => {
    const currentTaxNumber = formik.values[ClientFormTypes.FIELDS.TAX_NUMBER];
    setCurrentTaxNumber(currentTaxNumber);
  }, [formik.values]);

  useEffect(() => {
    if (!isDataSending && postResult) {
      navigate(`${PATH.CUSTOMER_PROFILE}/${postResult}`);
    }
  }, [isDataSending, inEditMode, postResult, navigate]);

  const fields: ClientFormTypes.FieldType[] = useMemo(() => {
    return [
      [
        {
          name: ClientFormTypes.FIELDS.TAX_NUMBER,
          label: 'NIP',
          value: formik.values[ClientFormTypes.FIELDS.TAX_NUMBER],
          error:
            formik.touched[ClientFormTypes.FIELDS.TAX_NUMBER] && !!formik.errors[ClientFormTypes.FIELDS.TAX_NUMBER],
          helperText:
            formik.touched[ClientFormTypes.FIELDS.TAX_NUMBER] && formik.errors[ClientFormTypes.FIELDS.TAX_NUMBER],
          styles: { width: '49%' },
          disabled: inEditMode,
          tooltipText: inEditMode ? (
            ''
          ) : (
            <span>
              Jeśli firma jest z Polski to numer NIP można wpisać bez prefiksu „PL” lub z prefiksem „PL” (obie formy są
              dopuszczalne).
              <br />
              <br />
              Jeśli firma jest z UE to należy wpisać numer NIP zawsze z prefiksem danego kraju.
            </span>
          )
        }
      ],
      [
        {
          name: ClientFormTypes.FIELDS.NAME,
          label: 'Nazwa klienta',
          value: formik.values[ClientFormTypes.FIELDS.NAME],
          error: formik.touched[ClientFormTypes.FIELDS.NAME] && !!formik.errors[ClientFormTypes.FIELDS.NAME],
          helperText: formik.touched[ClientFormTypes.FIELDS.NAME] && formik.errors[ClientFormTypes.FIELDS.NAME],
          styles: { width: '100%' }
        }
      ],
      [
        {
          name: ClientFormTypes.FIELDS.DESCRIPTION_1,
          label: 'Opis 1',
          value: formik.values[ClientFormTypes.FIELDS.DESCRIPTION_1],
          error:
            formik.touched[ClientFormTypes.FIELDS.DESCRIPTION_1] &&
            !!formik.errors[ClientFormTypes.FIELDS.DESCRIPTION_1],
          helperText:
            formik.touched[ClientFormTypes.FIELDS.DESCRIPTION_1] && formik.errors[ClientFormTypes.FIELDS.DESCRIPTION_1],
          styles: { width: '100%' }
        }
      ],
      [
        {
          name: ClientFormTypes.FIELDS.DESCRIPTION_2,
          label: 'Opis 2',
          value: formik.values[ClientFormTypes.FIELDS.DESCRIPTION_2],
          error:
            formik.touched[ClientFormTypes.FIELDS.DESCRIPTION_2] &&
            !!formik.errors[ClientFormTypes.FIELDS.DESCRIPTION_2],
          helperText:
            formik.touched[ClientFormTypes.FIELDS.DESCRIPTION_2] && formik.errors[ClientFormTypes.FIELDS.DESCRIPTION_2],
          styles: { width: '100%' }
        }
      ],
      [
        {
          name: ClientFormTypes.FIELDS.STREET,
          label: 'Ulica',
          value: formik.values[ClientFormTypes.FIELDS.STREET],
          error: formik.touched[ClientFormTypes.FIELDS.STREET] && !!formik.errors[ClientFormTypes.FIELDS.STREET],
          helperText: formik.touched[ClientFormTypes.FIELDS.STREET] && formik.errors[ClientFormTypes.FIELDS.STREET],
          styles: { width: '49%' }
        },
        {
          name: ClientFormTypes.FIELDS.BUILDING_NUMBER,
          label: 'Nr domu',
          value: formik.values[ClientFormTypes.FIELDS.BUILDING_NUMBER],
          error:
            formik.touched[ClientFormTypes.FIELDS.BUILDING_NUMBER] &&
            !!formik.errors[ClientFormTypes.FIELDS.BUILDING_NUMBER],
          helperText:
            formik.touched[ClientFormTypes.FIELDS.BUILDING_NUMBER] &&
            formik.errors[ClientFormTypes.FIELDS.BUILDING_NUMBER],
          styles: { width: '23.5%' }
        },
        {
          name: ClientFormTypes.FIELDS.APARTAMENT_NUMBER,
          label: 'Nr lokalu',
          value: formik.values[ClientFormTypes.FIELDS.APARTAMENT_NUMBER],
          error:
            formik.touched[ClientFormTypes.FIELDS.APARTAMENT_NUMBER] &&
            !!formik.errors[ClientFormTypes.FIELDS.APARTAMENT_NUMBER],
          helperText:
            formik.touched[ClientFormTypes.FIELDS.APARTAMENT_NUMBER] &&
            formik.errors[ClientFormTypes.FIELDS.APARTAMENT_NUMBER],
          styles: { width: '23.5%' }
        }
      ],
      [
        {
          name: ClientFormTypes.FIELDS.POSTCODE,
          label: 'Kod pocztowy',
          value: formik.values[ClientFormTypes.FIELDS.POSTCODE],
          error: formik.touched[ClientFormTypes.FIELDS.POSTCODE] && !!formik.errors[ClientFormTypes.FIELDS.POSTCODE],
          helperText: formik.touched[ClientFormTypes.FIELDS.POSTCODE] && formik.errors[ClientFormTypes.FIELDS.POSTCODE],
          styles: { width: '49%' }
        },
        {
          name: ClientFormTypes.FIELDS.CITY,
          label: 'Miejscowość',
          value: formik.values[ClientFormTypes.FIELDS.CITY],
          error: formik.touched[ClientFormTypes.FIELDS.CITY] && !!formik.errors[ClientFormTypes.FIELDS.CITY],
          helperText: formik.touched[ClientFormTypes.FIELDS.CITY] && formik.errors[ClientFormTypes.FIELDS.CITY],
          styles: { width: '49%' }
        }
      ]
    ];
  }, [formik.values, formik.touched, formik.errors, inEditMode]);

  const checkRequiredFields = () => {
    const requiredFields = [ClientFormTypes.FIELDS.NAME, ClientFormTypes.FIELDS.TAX_NUMBER];
    return requiredFields.every((field) => formik.values[field]);
  };

  const shouldFormBeDisabled =
    !checkRequiredFields() ||
    Object.keys(formik.errors).length > 0 ||
    validationErrors.length > 0 ||
    !!postResult ||
    !formik.dirty;

  const closeWindow = (): void => {
    window.close();
  };

  const isPolishTaxNumber = (): boolean => {
    return currentTaxNumber.startsWith('PL') || currentTaxNumber?.substring(0, 2)?.match(/^[0-9]+$/) ? true : false;
  };

  const acceptButtonProps: ButtonWithTooltipProps = {
    variant: 'contained',
    tooltipOptions: { active: !isEditable, text: tooltipImportInProgress, noIcon: true },
    disabled: shouldFormBeDisabled || isDataSending || !isEditable,
    type:
      inEditMode || (isPolishTaxNumber() && clearPolishTaxNumber(currentTaxNumber)?.length === 9) ? 'button' : 'submit',
    onClick: inEditMode ? toggleAcceptModal : undefined
  };

  const clearButtonProps: ButtonProps = {
    variant: 'contained',
    disabled: !formik.dirty,
    type: 'reset',
    onClick: () => {
      formik.resetForm();
      setSearchPhrase('');
      setInputValue(null);
    }
  };

  const cancelButtonLabel = inEditMode
    ? 'Czy chcesz anulować wprowadzone zmiany?'
    : 'Czy chcesz anulować dodanie nowego klienta?';

  const cancelDialogProps: DialogProps = {
    isOpen: isCancelModalOpen,
    closeModal: toggleCancelModal,
    handlePrimaryAction: inEditMode ? () => navigate(`${PATH.CUSTOMER_PROFILE}/${id}`) : closeWindow,
    primaryLabel: 'Tak',
    closeLabel: 'Nie'
  };

  const pageTitle = inEditMode ? 'Dane klienta' : 'Nowy klient';

  const onPasteHandle = useCallback((name: string) => formik.setFieldTouched(name), [formik]);

  const autocompleteOnOptionSelected = async (v: AutocompleteDto | null) => {
    // https://github.com/jaredpalmer/formik/issues/2059
    await Promise.all([
      await formik.setFieldValue(FIELDS.TAX_NUMBER, v?.taxIdentificationNumber ?? ''),
      await formik.setFieldValue(FIELDS.NAME, v?.name ?? ''),
      await formik.setFieldValue(FIELDS.STREET, v?.address?.street ?? ''),
      await formik.setFieldValue(FIELDS.BUILDING_NUMBER, v?.address?.buildingNumber ?? ''),
      await formik.setFieldValue(FIELDS.APARTAMENT_NUMBER, v?.address?.apartmentNumber ?? ''),
      await formik.setFieldValue(FIELDS.POSTCODE, v?.address?.postcode ?? ''),
      await formik.setFieldValue(FIELDS.CITY, v?.address?.city ?? ''),
      await formik.setFieldTouched(FIELDS.TAX_NUMBER),
      await formik.setFieldTouched(FIELDS.NAME)
    ]);
    await formik.validateForm();
  };

  const { result } = useFetch<ClientAutocompleteQueryResultDto>(
    useCallback(async () => {
      if (searchPhrase && searchPhrase !== '') return await getClientAutocomplete(searchPhrase ?? '');
      return null!;
    }, [searchPhrase])
  );

  const autocompleteOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = event.target.value;

    if (currentValue.length >= 3) {
      setSearchPhrase(event.target.value);
    } else {
      setSearchPhrase('');
    }
  };

  return (
    <Box sx={{ padding: '1rem 0' }}>
      <PageTitle sx={{ padding: '0 1.5rem' }} dataTest="user-form-title">
        {pageTitle}
      </PageTitle>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', borderBottom: '1px solid #E0E0E0', paddingBottom: '2.25rem' }}>
          <Box sx={{ padding: '0 1.5rem', width: '50%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {!inEditMode && (
                <Autocomplete
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  outerBoxSx={{
                    width: '100%',
                    marginTop: '1.5rem',
                    display: 'flex',
                    alignItems: 'flex-start',
                    position: 'relative'
                  }}
                  label="Wyszukaj klienta po NIP lub nazwie"
                  key="company_autocomplete"
                  onChangeHandler={autocompleteOnChangeHandler}
                  autofocus={!inEditMode}
                  onOptionSelected={autocompleteOnOptionSelected}
                  companies={result?.results}
                />
              )}
              {fields.map((field, index) => {
                return (
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} key={index}>
                    {field.map((sub, subindex) => {
                      return (
                        <TextField
                          key={sub.name}
                          field={sub}
                          validationErrors={validationErrors}
                          onChangeHandler={formik.handleChange}
                          onKeyPressHandler={formik.handleBlur}
                          errorsSetter={setValidationErrors}
                          autoFocus={false}
                          disabled={sub?.disabled || !!postResult}
                          extraStyles={sub?.styles}
                          tooltipText={sub?.tooltipText}
                          onPasteHandle={onPasteHandle}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
        <Stack spacing={3} direction="row" sx={{ padding: '1.5rem 1.5rem 0.5rem 1.5rem', textTransform: 'uppercase' }}>
          <ButtonWithTooltip {...acceptButtonProps} id="user-form-save-button">
            Zapisz
          </ButtonWithTooltip>
          <StyledButton {...clearButtonProps} id="user-form-clear-button">
            Wyczyść
          </StyledButton>
          <StyledButton variant="text" onMouseDown={toggleCancelModal} id="user-form-cancel-button">
            Anuluj
          </StyledButton>
        </Stack>
      </form>

      {inEditMode && (
        <Dialog
          isOpen={[...validationErrors].length === 0 && isAcceptModalOpen}
          isPending={isDataSending}
          closeModal={toggleAcceptModal}
          handlePrimaryAction={formik.submitForm}
          primaryLabel="Tak"
          closeLabel="Nie">
          Czy chcesz zapisać wprowadzone zmiany?
        </Dialog>
      )}

      <Dialog {...cancelDialogProps}>{cancelButtonLabel}</Dialog>
    </Box>
  );
};

export default ClientForm;
