import { FC, memo } from 'react';
import { Dialog } from 'components';
import { Props } from './CheckMonitoringDialog.types';
import classes from './styles.module.scss';

const CheckMonitorDialog: FC<Props> = memo(({ isOpen, cancelAction, accept }) => {
  return (
    <Dialog isOpen={isOpen} closeModal={cancelAction} primaryLabel="Tak" handlePrimaryAction={accept} closeLabel="Nie">
      <div>
        <span className={classes.header}>Nie jest możliwe wykonanie operacji w dniu dzisiejszym</span>
        Data operacji zostanie ustawiona na dzień jutrzejszy. Czy chcesz kontynuować?
      </div>
    </Dialog>
  );
});

export default CheckMonitorDialog;
