import { AxiosPromise } from 'axios';
import {
  ApiResponse,
  ExportCounterpartiesDto,
  LatestReportsDto,
  ReportDto,
  ReportItemDto,
  ReportUsersDto,
  ExportHistoryDto,
  ReportHistoryItemDto,
  ReportConfirmationByTokenDto
} from '../models';
import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { FormikSubmitValues } from 'domains/reportsWizard/shared';

export const addReportRequest = (
  companyId: string,
  taxId: string,
  counterpartyName: string
): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports`,
    method: 'POST',
    data: {
      taxIdentificationNumber: taxId,
      companyId: Number(companyId),
      counterpartyName: counterpartyName
    }
  });
};

export const getReportFile = (id: string): AxiosPromise<ApiResponse<ReportDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/${id}`,
    method: 'GET',
    responseType: 'blob'
  });
};

export const monthlyUsage = (companyId: string): AxiosPromise<ApiResponse<any>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/monthlyUsage/company/${companyId}`,
    method: 'GET'
  });
};

export const getDictionariesUsers = (companyId: string): AxiosPromise<ApiResponse<ReportUsersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/systemManagement/Dictionaries/Users?CompanyId=${companyId}`,
    method: 'GET'
  });
};

export const createReport = (values: FormikSubmitValues): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions`,
    method: 'POST',
    data: values
  });
};

export const getReportsList = (queryString?: string): AxiosPromise<ApiResponse<ReportUsersDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};

export const updateReport = (id: string, values: FormikSubmitValues): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions/${id}`,
    method: 'PUT',
    data: values
  });
};

export const deleteReport = (id: number): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions/${id}`,
    method: 'DELETE'
  });
};

export const getReport = (id: string): AxiosPromise<ApiResponse<ReportItemDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions/${id}`,
    method: 'GET'
  });
};

export const getAssignedReports = (id: string): AxiosPromise<ApiResponse<ReportItemDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/ReportDefinitions/User/${id}/AssignedReports`,
    method: 'GET'
  });
};

//@todo zgłosić response do poprawy (brakuje Result)
export const getLatestCompanyReports = (
  companyId: string,
  taxNumber: string
): AxiosPromise<ApiResponse<LatestReportsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/latest/company/${companyId}/counterparty/${taxNumber}`,
    method: 'GET'
  });
};

export const generateReport = (taxId: string, token: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/adhoc?token=${token}`,
    method: 'POST',
    data: {
      taxIdentificationNumber: taxId
    }
  });
};

export const getReportConfirmationSetting = (
  token: string
): AxiosPromise<ApiResponse<ReportConfirmationByTokenDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/adhoc/additionalConfirmation?token=${token}`,
    method: 'GET'
  });
};

export const reportStatus = (reportRequestId: number, token: string): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/adhoc/${reportRequestId}/status?token=${token}`,
    method: 'POST'
  });
};

export const getGeneratedReport = (
  reportRequestId: number,
  token: string
): AxiosPromise<ApiResponse<LatestReportsDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports/adhoc/${reportRequestId}?token=${token}`,
    method: 'GET',
    responseType: 'arraybuffer'
  });
};

export const exportToCsv = (data: ExportCounterpartiesDto): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Exports/counterparties`,
    method: 'POST',
    data
  });
};

export const exportHistoryToCsv = (data: ExportHistoryDto): AxiosPromise<ApiResponse<string>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Exports/changeHistory`,
    method: 'POST',
    data
  });
};

export const getReportsHistoryList = (queryString?: string): AxiosPromise<ApiResponse<ReportHistoryItemDto>> => {
  return axiosInstance({
    url: `${baseAPIUrl}/reporting/Reports`.concat(queryString ? `?${queryString}` : ''),
    method: 'GET'
  });
};
